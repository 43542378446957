import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  FETCH_ACTIVE_DRIVERS,
  FETCH_ACTIVE_DRIVERS_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  FETCH_ALL_USERS_STATIC,
  FETCH_ALL_USERS_STATIC_SUCCESS,
  FETCH_ALL_USERS_STATIC_FAILED,
  FETCH_APPROVED_DRIVERS_SUCCESS,
} from '../store/types'
import { debounce } from 'lodash'

export const fetchApprovedDrivers = () => (dispatch) => (firebase) => {
  const { usersRef } = firebase
  usersRef
    .orderByChild('usertype')
    .equalTo('driver')
    .once('value', (snapshot) => {
      if (snapshot.val()) {
        const data = snapshot.val()

        const approvedDrivers = Object.keys(data)
          .map((i) => {
            data[i].id = i
            return data[i]
          })
          .filter((driver) => driver.approved === true)

        dispatch({
          type: FETCH_APPROVED_DRIVERS_SUCCESS,
          payload: approvedDrivers,
        })
      }
    })
}

export const fetchUsers = () => (dispatch) => (firebase) => {
  const { usersRef } = firebase

  dispatch({
    type: FETCH_ALL_USERS,
    payload: null,
  })
  usersRef.once('value', (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val()
      const arr = Object.keys(data)
        .filter((i) => data[i]?.usertype !== 'admin')
        .map((i) => {
          data[i].id = i
          return data[i]
        })
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: arr,
      })
    } else {
      dispatch({
        type: FETCH_ALL_USERS_FAILED,
        payload: 'No users available.',
      })
    }
  })
}

export const fetchUsersOnce = () => (dispatch) => (firebase) => {
  const { usersRef } = firebase

  dispatch({
    type: FETCH_ALL_USERS_STATIC,
    payload: null,
  })

  usersRef.once('value', (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val()
      const arr = Object.keys(data).map((i) => {
        data[i].id = i
        return data[i]
      })
      dispatch({
        type: FETCH_ALL_USERS_STATIC_SUCCESS,
        payload: arr,
      })
    } else {
      dispatch({
        type: FETCH_ALL_USERS_STATIC_FAILED,
        payload: 'No users available.',
      })
    }
  })
}

// export const fetchDrivers = () => (dispatch) => (firebase) => {

//   const {
//     usersRef
//   } = firebase;

//   dispatch({
//     type: FETCH_ALL_USERS,
//     payload: null
//   });

//   usersRef.orderByChild("queue").equalTo(false).once("value", snapshot => {
//     if (snapshot.val()) {
//       const data = snapshot.val();
//       const arr = Object.keys(data)
//       .filter(i => data[i].usertype=='driver' && data[i].approved == true && data[i].driverActiveStatus == true && data[i].location)
//       .map(i => {
//         return {
//           id: i,
//           location: data[i].location,
//           carType: data[i].carType
//         };
//       });
//       dispatch({
//         type: FETCH_ALL_USERS_SUCCESS,
//         payload: arr
//       });
//     } else {
//       dispatch({
//         type: FETCH_ALL_USERS_FAILED,
//         payload: "No users available."
//       });
//     }
//   });
// };

export const fetchActiveDrivers = () => (dispatch) => (firebase) => {
  const { usersRef } = firebase

  dispatch({ type: FETCH_ACTIVE_DRIVERS, payload: null })

  const query = usersRef.orderByChild('usertype').equalTo('driver')

  const handleDriverUpdate = debounce((updatedDrivers) => {
    dispatch({
      type: FETCH_ACTIVE_DRIVERS_SUCCESS,
      payload: updatedDrivers,
    })
  }, 200)

  const drivers = {}

  const processDriverSnapshot = (snapshot) => {
    const data = snapshot.val()
    if (
      data?.approved === true &&
      data.driverActiveStatus === true &&
      data.location
    ) {
      drivers[snapshot.key] = {
        id: snapshot.key,
        location: data.location,
        carType: data.carType,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        queue: data.queue,
        vehicleNumber: data.vehicleNumber,
        auto_accept_booking: data.auto_accept_booking || false,
        customDriverRadius: data.customDriverRadius || 2,
      }
      handleDriverUpdate(Object.values(drivers))
    } else {
      if (drivers[snapshot.key]) {
        delete drivers[snapshot.key]
        handleDriverUpdate(Object.values(drivers))
      }
    }
  }

  query.on('child_added', processDriverSnapshot)
  query.on('child_changed', processDriverSnapshot)
  query.on('child_removed', (snapshot) => {
    if (drivers[snapshot.key]) {
      delete drivers[snapshot.key]
      handleDriverUpdate(Object.values(drivers))
    }
  })

  return () => {
    query.off('child_added', processDriverSnapshot)
    query.off('child_changed', processDriverSnapshot)
    query.off('child_removed')
  }
}

export const fetchDrivers = () => (dispatch) => (firebase) => {
  const { usersRef } = firebase

  dispatch({ type: FETCH_ALL_USERS, payload: null })

  const query = usersRef.orderByChild('queue').equalTo(false)

  const handleDriverUpdate = debounce((updatedDrivers) => {
    dispatch({
      type: FETCH_ALL_USERS_SUCCESS,
      payload: updatedDrivers,
    })
  }, 200)

  const drivers = {}

  const processDriverSnapshot = (snapshot) => {
    const data = snapshot.val()
    if (
      data?.usertype === 'driver' &&
      data.approved === true &&
      data.driverActiveStatus === true &&
      data.location
    ) {
      drivers[snapshot.key] = {
        id: snapshot.key,
        location: data.location,
        carType: data.carType,
        auto_accept_booking: data.auto_accept_booking || false,
        customDriverRadius: data.customDriverRadius || 2,
        firstName: data.firstName,
        lastName: data.lastName,
        queue: data.queue
      }
      handleDriverUpdate(Object.values(drivers))
    } else {
      if (drivers[snapshot.key]) {
        delete drivers[snapshot.key]
        handleDriverUpdate(Object.values(drivers))
      }
    }
  }

  query.on('child_added', processDriverSnapshot)
  query.on('child_changed', processDriverSnapshot)
  query.on('child_removed', (snapshot) => {
    if (drivers[snapshot.key]) {
      delete drivers[snapshot.key]
      handleDriverUpdate(Object.values(drivers))
    }
  })

  return () => {
    query.off('child_added', processDriverSnapshot)
    query.off('child_changed', processDriverSnapshot)
    query.off('child_removed')
  }
}

export const fetchCompanies = async (firebase) => {
  const { database } = firebase
  const res = (await database.ref('companies').once('value')).val()
  if (res) {
    return Object.keys(res).map((key) => ({
      id: key,
      ...res[key],
    }))
  }
  return []
}

export const getCompanyById = (id) => async (firebase) => {
  const { database } = firebase
  const res = (await database.ref(`companies/${id}`).once('value')).val()
  return { id, ...res }
}

export const getDriverById = (id) => async (firebase) => {
  const { database } = firebase
  const res = (await database.ref(`users/${id}`).once('value')).val()
  return { id, ...res }
}

export const getUsersByType = (type) => async (firebase) => {
  const { database } = firebase
  const snapshot = await database
    .ref('users')
    .orderByChild('usertype')
    .equalTo(type)
    .once('value')

  const users = snapshot.val()
  const usersList = []

  for (const id in users) {
    usersList.push({ id, ...users[id] })
  }

  return usersList
}

export const saveCustomerFeedback = (data) => async (firebase) => {
  const { database } = firebase
  try {
    const res = await database.ref(`customers_feedbacks`).push(data)
    return {
      ...res,
      success: true,
      message: 'Feedback saved successfully',
    }
  } catch (error) {
    return { success: false, message: error.message }
  }
}

export const fetchCustomersFeedbacks = async (firebase) => {
  const { database } = firebase
  const res = (await database.ref('customers_feedbacks').once('value')).val()
  if (res) {
    return Object.keys(res).map((key) => ({
      id: key,
      ...res[key],
    }))
  }
  return []
}

export const saveLocationToUser = (userId, data) => async (firebase) => {
  const { database } = firebase
  try {
    const ref = database.ref(`saved_locations/${userId}`)

    if (data.type === 'latest') {
      // Fetch all entries for the user where type is 'latest'
      const snapshot = await ref
        .orderByChild('type')
        .equalTo('latest')
        .once('value')

      const allowLatest = 5
      // Check if there are more than 5 entries
      if (snapshot.numChildren() >= allowLatest) {
        const entriesToRemove = snapshot.numChildren() - (allowLatest - 1)
        let count = 0

        // Create an array of keys sorted by creation time (Firebase keys are ordered by default)
        const keysToRemove = []
        snapshot.forEach((childSnapshot) => {
          if (count < entriesToRemove) {
            keysToRemove.push(childSnapshot.key)
            count++
          }
        })

        // Remove the oldest entries
        const removePromises = keysToRemove.map((key) =>
          ref.child(key).remove()
        )
        await Promise.all(removePromises)
      }
    }

    // Add the new entry
    const newEntryRef = await ref.push()

    const res = await newEntryRef.set(data)
    return {
      userId,
      ...res,
      success: true,
      message: 'Location saved successfully',
    }
  } catch (error) {
    return { success: false, message: error.message }
  }
}

export const deleteLocationFromUser =
  (userId, locationId) => async (firebase) => {
    const { database } = firebase
    try {
      const locationRef = database.ref(
        `saved_locations/${userId}/${locationId}`
      )
      await locationRef.remove()
      return { success: true, message: 'Location deleted successfully' }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

export const getSavedLocationsForUser = (userId, type) => async (firebase) => {
  const { database } = firebase
  try {
    const snapshot = await database
      .ref(`saved_locations/${userId}`)
      .orderByChild('type')
      .equalTo(type)
      .once('value')

    const locations = snapshot.val()
    return locations || {}
  } catch (error) {
    throw error
  }
}

export const checkPartnerTimes = (companyId) => (firebase) => {
  return new Promise((resolve, reject) => {
    const { config } = firebase
    fetch(`https://${config.projectId}.web.app/check_partner`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyId,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json) {
          resolve(json)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const addUser = (userdata) => (dispatch) => (firebase) => {
  const { usersRef } = firebase

  dispatch({
    type: EDIT_USER,
    payload: userdata,
  })

  usersRef
    .push(userdata)
    .then(() => {
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: null,
      })
    })
    .catch((error) => {
      dispatch({
        type: EDIT_USER_FAILED,
        payload: error,
      })
    })
}

export const editUser = (id, user) => (dispatch) => (firebase) => {
  const { singleUserRef } = firebase

  dispatch({
    type: EDIT_USER,
    payload: user,
  })
  let editedUser = user
  delete editedUser.id
  singleUserRef(id)
    .set(editedUser)
    .then(() => {
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: null,
      })
    })
    .catch((error) => {
      dispatch({
        type: EDIT_USER_FAILED,
        payload: error,
      })
    })
}

export const updateDocumentsImage =
  (user, imageBlob, docType) => (dispatch) => async (firebase) => {
    const {
      singleUserRef,
      carImageRef,
      frontIDImageRef,
      backIDImageRef,
      taxiLicenseImageRef,
      drivingLicenseImageRef,
      driverImageRef,
      vehicleInsuranceImageRef,
    } = firebase

    let image
    let profile = user

    if (docType === 'carImage') {
      await carImageRef(user.id).put(imageBlob)
      image = await carImageRef(user.id).getDownloadURL()
      profile.carImage = image
    } else if (docType === 'frontIDImage') {
      await frontIDImageRef(user.id).put(imageBlob)
      image = await frontIDImageRef(user.id).getDownloadURL()
      profile.frontIDImage = image
    } else if (docType === 'backIDImage') {
      await backIDImageRef(user.id).put(imageBlob)
      image = await backIDImageRef(user.id).getDownloadURL()
      profile.backIDImage = image
    } else if (docType === 'taxiLicenseImage') {
      await taxiLicenseImageRef(user.id).put(imageBlob)
      image = await taxiLicenseImageRef(user.id).getDownloadURL()
      profile.taxiLicenseImage = image
    } else if (docType === 'drivingLicenseImage') {
      await drivingLicenseImageRef(user.id).put(imageBlob)
      image = await drivingLicenseImageRef(user.id).getDownloadURL()
      profile.drivingLicenseImage = image
    } else if (docType === 'driverImage') {
      await driverImageRef(user.id).put(imageBlob)
      image = await driverImageRef(user.id).getDownloadURL()
      profile.driverImage = image
    } else if (docType === 'vehicleInsuranceImage') {
      await vehicleInsuranceImageRef(user.id).put(imageBlob)
      image = await vehicleInsuranceImageRef(user.id).getDownloadURL()
      profile.vehicleInsuranceImage = image
    }

    singleUserRef(user.id).update(profile)
    dispatch({
      type: EDIT_USER,
      payload: user,
    })
  }

export const deleteUser = (id) => (dispatch) => (firebase) => {
  const { singleUserRef } = firebase

  dispatch({
    type: DELETE_USER,
    payload: id,
  })

  singleUserRef(id)
    .remove()
    .then(() => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: null,
      })
    })
    .catch((error) => {
      dispatch({
        type: DELETE_USER_FAILED,
        payload: error,
      })
    })
}
