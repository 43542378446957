import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CircularLoading from '../components/CircularLoading'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment/min/moment-with-locales'
import { FirebaseContext } from 'common/src'

export default function WalletCardHistoryStatistics() {
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()

  const [totalWalletCreditAmount, setTotalWalletCreditAmount] = useState(0)
  const [totalAdminCreditAmount, setTotalAdminCreditAmount] = useState(0)
  const paymentmethods = useSelector((state) => state.paymentmethods)
  const [data, setData] = useState([])

  const { api } = useContext(FirebaseContext)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(api.fetchWalletCardHistory())
    return () => {
      // Cleanup function if needed
      api.fetchWalletCardHistory()
    }
  }, [dispatch, api])

  useEffect(() => {
    if (paymentmethods.walletCreditHistoryData !== null) {
      setData(paymentmethods.walletCreditHistoryData)
    }
  }, [paymentmethods.walletCreditHistoryData])

  useEffect(() => {
    if (paymentmethods && paymentmethods?.walletCreditHistoryData !== null) {
      let totalWallet = 0
      let totalAdmin = 0

      paymentmethods.walletCreditHistoryData.forEach((item) => {
        if (item.txRef === 'WalletCredit') {
          totalWallet += item.amount
        } else if (item.txRef === 'AdminCredit') {
          totalAdmin += item.amount
        }
      })

      setTotalWalletCreditAmount(totalWallet)
      setTotalAdminCreditAmount(totalAdmin)
    }
  }, [paymentmethods.walletCreditHistoryData, paymentmethods])

  const columns = [
    {
      title: t('fullName'),
      field: 'user.fullName',
      editable: 'never',
    },
    {
      title: t('mobile'),
      field: 'user.mobile',
      editable: 'never',
    },
    {
      title: t('amount'),
      field: 'amount',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('date'),
      field: 'date',
      render: (rowData) =>
        rowData.date ? moment(rowData.date).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('txRef'),
      field: 'txRef',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('id'),
      field: 'user.id',
      editable: 'never',
    },
  ]

  return paymentmethods.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Typography
        component="h4"
        variant="h4"
        style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
      >
        Wallet Card Statistics
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px',
          marginTop: '30px',
        }}
      >
        <div>
          <Typography
            component="h5"
            variant="h5"
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Total Purchased Amount:
          </Typography>

          <Typography
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {totalWalletCreditAmount} MKD
          </Typography>
        </div>

        <div>
          <Typography
            component="h5"
            variant="h5"
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
          >
            Total Admin Credit Amount:
          </Typography>

          <Typography
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            {totalAdminCreditAmount} MKD
          </Typography>
        </div>
      </div>

      {paymentmethods && (
        <MaterialTable
          title={t('walletAddedTransactions')}
          columns={columns}
          style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
          data={data}
          options={{
            sorting: true,
            pageSize: 10,
          }}
          localization={{
            body: {
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              emptyDataSourceMessage: t('blank_message'),
              editRow: {
                deleteText: t('delete_message'),
                cancelTooltip: t('cancel'),
                saveTooltip: t('save'),
              },
            },
            toolbar: {
              searchPlaceholder: t('search'),
              exportTitle: t('export'),
            },
            header: {
              actions: t('actions'),
            },
            pagination: {
              labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
              labelRowsSelect: t('rows'),
              firstTooltip: t('first_page_tooltip'),
              previousTooltip: t('previous_page_tooltip'),
              nextTooltip: t('next_page_tooltip'),
              lastTooltip: t('last_page_tooltip'),
            },
          }}
        />
      )}
    </div>
  )
}
