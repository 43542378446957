import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

function DateTimePicker(props) {
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('YYYY-MM-DDTHH:mm:ss')
  )
  // const [selectedTime, setSelectedTime] = useState(dayjs().format('HH:mm:ss'));

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value)
  }

  // const handleTimeChange = (event) => {
  //     setSelectedTime(event.target.value);
  // };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedDate)
    }
  }, [props, selectedDate])

  const styles = {
    label: {
      marginRight: '10px',
      fontFamily: 'Arial, sans-serif',
    },
    input: {
      border: '2px solid #4CAF50',
      borderRadius: '4px',
      padding: '5px',
      marginRight: '10px',
    },
    text: {
      fontFamily: 'Arial, sans-serif',
    },
  }

  return (
    <div>
      <label htmlFor={props.name} style={styles.label}>
        Choose {props.label} date {props.showTime ? 'and time' : ''}:
      </label>
      <input
        type={`${props.showTime ? 'datetime-local' : 'date'}`}
        id={`${props.name}-date`}
        name={`${props.name}-date`}
        value={selectedDate}
        style={styles.input}
        onChange={handleDateChange}
      />
      {/* {props.showTime && <input
                type="time"
                id={`${props.name}-time`}
                name={`${props.name}-time`}
                value={selectedTime}
                style={styles.input}
                onChange={handleTimeChange}
            />} */}
    </div>
  )
}

export default DateTimePicker
