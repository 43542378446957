export const FirebaseConfig = {
	"projectId": "beeride-c9f72",
	"appId": "1:771541853772:web:80e9dd8e491f379b97b6e9",
	"databaseURL": "https://beeride-c9f72-default-rtdb.firebaseio.com",
	"storageBucket": "beeride-c9f72.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDs4taErmqBN25T_j0je6_AjpgZObmTOi0",
	"authDomain": "beeride.eu",
	"messagingSenderId": "771541853772",
	"measurementId": "G-1C7JR2YWBM"
};