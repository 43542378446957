import React from 'react'

import { useSelector } from 'react-redux'

export default function DownloadPage() {
  const settings = useSelector((state) => state.settingsdata.settings)
  function redirectToStore() {
    const userAgent = navigator.userAgent + navigator.vendor + window.opera

    // Android device
    if (/android/i.test(userAgent) && settings.PlayStoreLink) {
      window.location.href = settings.PlayStoreLink
    }
    // iOS device
    else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !window.MSStream &&
      settings.AppleStoreLink
    ) {
      window.location.href = settings.AppleStoreLink
    }
    // Other devices (Web)
    else {
      window.location.href = settings.CompanyWebsite
    }
  }

  // Call the function
  redirectToStore()

  return <div></div>
}
