


import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '480px',
};

const Map = (props) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [icons, setIcons] = useState({});
  const [,setLoadingIcons] = useState(true);



  useEffect(() => {
    if (window?.google && window?.google?.maps) {
      setGoogleMapsLoaded(true);
    } else {
      const checkGoogleMaps = setInterval(() => {
        if (window?.google && window?.google?.maps) {
          setGoogleMapsLoaded(true);
          clearInterval(checkGoogleMaps);
        }
      }, 100);
    }
  }, []);

 

  const getColors = (queue) => {
    return {
      borderColor: !queue ? 'green' : 'red',
      fillColor: !queue ? 'rgba(0, 128, 0, 0.18)' : 'rgba(255, 0, 0, 0.18)',
    };
  };

  

  

  useEffect(() => {
    const addBorderToIcon = (url, queue) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
  
        img.onload = () => {
          const { borderColor, fillColor } = getColors(queue);
          const borderSize = 15;
          const whiteSpace = 10;
          const radius = (img.width / 2) + borderSize + whiteSpace;
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          canvas.width = canvas.height = radius * 2;
  
          ctx.fillStyle = fillColor;
          ctx.beginPath();
          ctx.arc(radius, radius, radius, 0, Math.PI * 2);
          ctx.fill();
  
          ctx.save();
          ctx.beginPath();
          ctx.arc(radius, radius, img.width / 2, 0, Math.PI * 2);
          ctx.clip();
          ctx.drawImage(
            img,
            radius - img.width / 2,
            radius - img.height / 2,
            img.width,
            img.height
          );
          ctx.restore();
  
          ctx.strokeStyle =borderColor;
          ctx.lineWidth = borderSize;
          ctx.beginPath();
          ctx.arc(radius, radius, img.width / 2 + borderSize / 2, 0, Math.PI * 2);
          ctx.stroke();
  
          const dataURL = canvas.toDataURL();
          resolve(dataURL);
        };
      });
    };

    const loadIcons = async () => {
      const newIcons = {};
      for (let marker of props.locations) {
        if (!icons[marker.id]) {
          const queue = marker.queue !== undefined ? marker.queue : false;
          console.log(`Marker ID: ${marker.id}, Queue: ${queue}`);
          const dataURL = await addBorderToIcon(marker.carImage, queue);
          newIcons[marker.id] = dataURL;
        }
      }
      setIcons((prevIcons) => ({ ...prevIcons, ...newIcons }));
      setLoadingIcons(false);
      console.log('Icons after loading:', newIcons);
    };

    if (props.locations.length > 0) {
      loadIcons();
    }
  }, [props.locations, icons]);


  if (!googleMapsLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      zoom={12}
      center={props.mapcenter}
      mapContainerStyle={containerStyle}
    >
      {props.locations.map((marker) => (
        <Marker
          position={{ lat: marker.lat, lng: marker.lng }}
          key={marker.id}
          icon={{
            url: marker.carImage,
            scaledSize: new window.google.maps.Size(35, 25),
          }}
          onClick={() => setSelectedMarker(marker)}
        />
      ))}

      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div style={{ marginBottom: '20px' }}>
            {selectedMarker.drivername}
            <br />
            {selectedMarker.carnumber}
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Map;
