import { getDirectionsApi } from './GoogleAPIFunctions'

// Function to convert degrees to radians
function toRadians(degrees) {
  return (degrees * Math.PI) / 180
}

// Function to calculate the distance between two points using the Haversine formula
function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371e3 // Earth's radius in meters
  const phi1 = toRadians(lat1)
  const phi2 = toRadians(lat2)
  const deltaPhi = toRadians(lat2 - lat1)
  const deltaLambda = toRadians(lon2 - lon1)

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c // Distance in meters
}

// Function to calculate total distance between multiple waypoints
function calculateTotalDistance(waypoints) {
  let totalDistance = 0
  for (let i = 0; i < waypoints.length - 1; i++) {
    const wp1 = waypoints[i]
    const wp2 = waypoints[i + 1]
    totalDistance += haversineDistance(
      wp1.latitude,
      wp1.longitude,
      wp2.latitude,
      wp2.longitude
    )
  }
  return totalDistance // Total distance in meters
}

export const GetDistance = (lat1, lon1, lat2, lon2) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344
    return dist
  }
}

export const GetTripDistance = (data) => async (firebase) => {
  if (data) {
    const arr = Object.keys(data)
      .filter(function (i) {
        return data[i].status == 'STARTED' || data[i].status == 'REACHED'
      })
      .map((i) => {
        data[i].id = i
        return data[i]
      })
    let distance = 0
    let coords = []
    try {
      if (arr.length == 0 || arr.length == 1) {
        distance = 0
      } else if (arr.length == 2) {
        let startLoc = arr[0].lat + ',' + arr[0].lng
        let destLoc = arr[1].lat + ',' + arr[1].lng
        let res = await getDirectionsApi(startLoc, destLoc, null)(firebase)
        distance = res.distance_in_km
        coords.push({ latitude: arr[0].lat, longitude: arr[0].lng })
        coords.push({ latitude: arr[1].lat, longitude: arr[1].lng })
      } else {
        // let gaptokeep = 1
        // if (arr.length > 25) {
        //   gaptokeep = parseInt(arr.length / 25) + 1
        // }
        // let waypoints = ''
        coords.push({ latitude: arr[0].lat, longitude: arr[0].lng })
        // const origin = arr[0].lat + ',' + arr[0].lng
        for (let i = 1; i < arr.length - 1; i++) {
          // if (i % gaptokeep == 0) {
          //   waypoints = waypoints + arr[i].lat + ',' + arr[i].lng
          //   if (i < arr.length - 2) {
          //     waypoints = waypoints + '|'
          //   }
          // }
          coords.push({ latitude: arr[i].lat, longitude: arr[i].lng })
        }
        coords.push({
          latitude: arr[arr.length - 1].lat,
          longitude: arr[arr.length - 1].lng,
        })
        // const destination =
        //   arr[arr.length - 1].lat + ',' + arr[arr.length - 1].lng
        // let res = await getDirectionsApi(
        //   origin,
        //   destination,
        //   waypoints
        // )(firebase)
        try {
          distance = (calculateTotalDistance(coords) / 1000).toFixed(2)
        } catch (e) {
          distance = 0
        }
      }
    } catch (error) {
      console.log(error)
    }
    return {
      distance: distance,
      coords: coords,
    }
  } else {
    return {
      distance: 0,
      coords: [],
    }
  }
}
