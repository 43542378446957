export const colors = {
  LandingPage_Front: '#F1F2F1',
  LandingPage_Background: '#E9E9E1',
  LandingPage_Description: '#373737',
  Button_Primary: '#46F34D',
  Button_Secondary: '#239B28',
  Header_Background: '#FFFFFF',
  Header_Content: '#000000',
  AppMenu_Header: '#474747',
  ResponsiveDrawer_Header: '#444444',
  Yellow: '#FFDD33',
}
