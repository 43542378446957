import React from 'react'

import { Tooltip, Typography, styled, withStyles } from '@material-ui/core'

const TruncatedTextWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
}))

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '16px',
  },
})(Tooltip)

const TruncatedText = ({ text, limit = 40 }) => {
  if (!text) {
    return null
  }
  const truncated =
    text.length > limit ? text.substring(0, limit) + '...' : text

  return (
    <CustomTooltip placement="top" enterDelay={300} title={text} arrow>
      <TruncatedTextWrapper>
        <Typography variant="body2">{truncated}</Typography>
      </TruncatedTextWrapper>
    </CustomTooltip>
  )
}

export default TruncatedText
