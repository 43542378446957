import {
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILED,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_SUCCESS,
  UPDATE_WALLET_BALANCE_FAILED,
  CLEAR_PAYMENT_MESSAGES,
  FETCH_WALLET_CREDIT_HISTORY_SUCCESS,
  FETCH_WALLET_CREDIT_HISTORY_FAILED,
  FETCH_WALLET_CREDIT_HISTORY,
} from '../store/types'

export const INITIAL_STATE = {
  providers: [],
  message: null,
  loading: false,
  walletCreditHistoryData: null,
}

export const paymentreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS:
      return {
        ...state,
      }
    case FETCH_WALLET_CREDIT_HISTORY:
      return {
        ...state,
        loading: true,
      }
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        providers: action.payload,
      }
    case FETCH_WALLET_CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        walletCreditHistoryData: action.payload?.reverse(),
        loading: false,
      }
    case FETCH_WALLET_CREDIT_HISTORY_FAILED:
      return {
        ...state,
        walletCreditHistoryData: null,
        loading: false,
      }
    case FETCH_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        message: action.payload,
      }
    case UPDATE_WALLET_BALANCE:
      return {
        ...state,
      }
    case UPDATE_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_WALLET_BALANCE_FAILED:
      return {
        ...state,
        message: action.payload,
      }
    case CLEAR_PAYMENT_MESSAGES:
      return {
        ...state,
        message: null,
      }
    default:
      return state
  }
}
