import React, { useRef } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'assets/scss/material-kit-react.scss?v=1.9.0'
import LandingPage from './views/LandingPage.js'
import LoginPage from './views/LoginPage.js'
import DownloadPage from './views/DownloadPage.js'
import PrivacyPolicy from './views/PrivacyPolicy.js'
import AuthLoading from './views/AuthLoading'
import { Provider } from 'react-redux'
import ProtectedRoute from './views/ProtectedRoute'
import MyProfile from './views/MyProfile'
import BookingHistory from './views/BookingHistory'
import Dashboard from './views/Dashboard'
import CarTypes from './views/CarTypes'
import Promos from './views/Promos'
import Riders from './views/Riders'
import Drivers from './views/Drivers'
import FleetAdmins from './views/FleetAdmins'
import Notifications from './views/Notifications'
import DriverEarning from './views/DriverEarning'
import Earningreports from './views/Earningreports'
import Settings from './views/Settings'
import LanguageSetting from './views/LanguageSetting'
import AppInformation from './views/AppInformation'
import Withdraws from './views/Withdraws'
import CancellationReasons from './views/CancellationReasons'
import AddMoney from './views/AddMoney'
import { FirebaseProvider, store } from 'common/src'
import { FirebaseConfig } from './config/FirebaseConfig'
import { GoogleMapApiConfig } from './config/GoogleMapApiConfig'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { AppCat } from './config/AppCat'
import CreateAdmin from './views/CreateAdmin'
import ContactUs from './views/ContactUs'
import UserWallet from './views/UserWallet'
import AdvancedBookings from 'views/AdvancedBookings/AdvancedBookings.jsx'
import RequestsWomen from 'views/RequestsWomen'
import Companies from 'views/Companies.js'
import PrintInvoice from 'views/AdvancedBookings/Printinvoice.jsx'
import WalletCardHistoryStatistics from 'views/WalletCardHistoryStatistics.js'
import CustomersFeedbacks from 'views/CustomersFeedbacks.js'

i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
  },
})

function loadScript(src, position, id) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

function App() {
  const loaded = useRef(false)
  if (typeof window !== 'undefined' && !loaded.current && !window.google) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=' +
          GoogleMapApiConfig +
          '&libraries=geometry,drawing,places',
        document.querySelector('head'),
        'google-maps'
      )
    }
    loaded.current = true
  }
  return (
    <Provider store={store}>
      <FirebaseProvider config={FirebaseConfig} appcat={AppCat}>
        <AuthLoading>
          <BrowserRouter>
            <Routes>
              <Route
                path="/bookings"
                element={
                  <ProtectedRoute permit={'rider,admin,driver,fleetadmin'}>
                    <BookingHistory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute permit={'rider,admin,driver,fleetadmin'}>
                    <MyProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute permit={'admin,fleetadmin'}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cartypes"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <CarTypes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cancelreasons"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <CancellationReasons />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/addbookings"
                element={
                  <ProtectedRoute permit={'admin,fleetadmin,customer'}>
                    <AddBookings />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/promos"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <Promos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/riders"
                element={
                  <ProtectedRoute exact path="/riders" permit={'admin'}>
                    {' '}
                    <Riders />{' '}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/request-women"
                element={
                  <ProtectedRoute exact path="/request-women" permit={'admin'}>
                    <RequestsWomen />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/addtowallet"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <AddMoney />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/userwallet"
                element={
                  <ProtectedRoute permit={'rider,driver'}>
                    <UserWallet />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/withdraws"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <Withdraws />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/advance-bookings"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <AdvancedBookings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customers-feedbacks"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <CustomersFeedbacks />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/partner-invoices"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <PrintInvoice />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/drivers"
                element={
                  <ProtectedRoute permit={'admin,fleetadmin'}>
                    {' '}
                    <Drivers />{' '}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/alladmin"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <CreateAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/fleetadmins"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <FleetAdmins />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/driverearning"
                element={
                  <ProtectedRoute permit={'admin,fleetadmin'}>
                    <DriverEarning />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/earningreports"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <Earningreports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/wallet-card-statistics"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <WalletCardHistoryStatistics />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/appinfo"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <AppInformation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/languagesetting"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <LanguageSetting />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute permit={'admin'}>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route path="/download" element={<DownloadPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/companies"
                element={
                  <ProtectedRoute permit={'admin'}>
                    {' '}
                    <Companies />{' '}
                  </ProtectedRoute>
                }
              />
              <Route path="/" element={<LandingPage />} />
            </Routes>
          </BrowserRouter>
        </AuthLoading>
      </FirebaseProvider>
    </Provider>
  )
}

export default App
