import React, { useState, useEffect, useContext, useRef } from 'react'
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux'
import CircularLoading from '../components/CircularLoading'
import { FirebaseContext } from 'common/src'
import { useTranslation } from 'react-i18next'

export default function RequestsWomen() {
  const { api } = useContext(FirebaseContext)
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const { editUser, fetchUsersOnce } = api
  const settings = useSelector((state) => state.settingsdata.settings)
  const [data, setData] = useState([])
  const staticusers = useSelector((state) => state.usersdata.staticusers)
  const dispatch = useDispatch()
  const loaded = useRef(false)

  useEffect(() => {
    dispatch(fetchUsersOnce())
  }, [dispatch, fetchUsersOnce])
  useEffect(() => {
    if (staticusers) {
      setData(
        staticusers.filter(
          (user) =>
            user.usertype === 'rider' &&
            user?.gender === 'Female' &&
            user?.frontIDImage &&
            !user?.womenApproved
        )
      )
    } else {
      setData([])
    }
    loaded.current = true
  }, [staticusers])

  const columns = [
    {
      title: t('first_name'),
      field: 'firstName',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('last_name'),
      field: 'lastName',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('mobile'),
      field: 'mobile',
      editable: 'onAdd',
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.mobile : 'Hidden for Demo',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('gender'),
      field: 'gender',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: 'Front ID',
      field: 'frontIDImage',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
      render: (rowData) =>
        rowData?.frontIDImage && (
          <img
            alt="Front ID"
            src={rowData?.frontIDImage}
            style={{ width: 50 }}
          />
        ),
    },
    {
      title: 'Women Approved',
      field: 'womenApproved',
      type: 'boolean',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
  ]

  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={t('riders')}
      columns={columns}
      style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
      data={data}
      options={{
        sorting: true,
      }}
      localization={{
        body: {
          addTooltip: t('add'),
          deleteTooltip: t('delete'),
          editTooltip: t('edit'),
          emptyDataSourceMessage: t('blank_message'),
          editRow: {
            deleteText: t('delete_message'),
            cancelTooltip: t('cancel'),
            saveTooltip: t('save'),
          },
        },
        toolbar: {
          searchPlaceholder: t('search'),
        },
        header: {
          actions: t('actions'),
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
          labelRowsSelect: t('rows'),
          firstTooltip: t('first_page_tooltip'),
          previousTooltip: t('previous_page_tooltip'),
          nextTooltip: t('next_page_tooltip'),
          lastTooltip: t('last_page_tooltip'),
        },
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve()
              dispatch(editUser(oldData.id, newData))
              dispatch(fetchUsersOnce())
            }, 600)
          }),
      }}
    />
  )
}
