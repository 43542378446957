import React, { useState } from 'react'

function CheckboxComponent({ label, onChange }) {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
    if (onChange) {
      onChange(event.target.checked)
    }
  }

  const styles = {
    label: {
      marginRight: '10px',
      fontFamily: 'Arial, sans-serif',
    },
    checkbox: {
      margin: '10px',
    },
  }

  return (
    <div>
      <label htmlFor="custom-checkbox" style={styles.label}>
        {label}
      </label>
      <input
        type="checkbox"
        id="custom-checkbox"
        checked={isChecked}
        style={styles.checkbox}
        onChange={handleCheckboxChange}
      />
    </div>
  )
}

export default CheckboxComponent
