import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-kit-react/components/appCard'
import { useSelector } from 'react-redux'
const useStyles = makeStyles(styles)
export const AppCard = ({ type }) => {
  const classes = useStyles()
  const icon =
    type === 'android'
      ? require('assets/img/google-icon-white.svg').default
      : require('assets/img/apple-icon-white.svg').default
  const title = type === 'android' ? 'Google Play' : 'App Store'
  const settings = useSelector((state) => state.settingsdata.settings)
  const linkTo =
    type === 'android' ? settings.PlayStoreLink : settings.AppleStoreLink

  return (
    <a href={linkTo}>
      <div className={classes.cardWrapper}>
        <img src={icon} alt="Download Icon" className={classes.img} />
        <div className={classes.textWrapper}>
          <span className={classes.downloadText}>Download on the</span>
          <span className={classes.platform}>{title}</span>
        </div>
      </div>
    </a>
  )
}
