import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from 'components/Header/Header.js'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import Parallax from 'components/Parallax/Parallax.js'
import { colors } from '../components/Theme/WebTheme'
import { Grid } from '@material-ui/core'
import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import { AppCard } from 'components/AppCard/AppCard'
const dashboardRoutes = []

const useStyles = makeStyles((theme) => ({
  ...styles,
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inputRtl: {
    '& label': {
      right: 25,
      left: 'auto',
    },
    '& legend': {
      textAlign: 'right',
      marginRight: 18,
    },
  },
  iconStyle: {
    width: '200px',
    height: '200px',
    marginRight: '30px',
    '@media (max-width: 1600px)': {
      width: '180px',
      height: '180px',
    },
    '@media (max-width: 1150px)': {
      width: '150px',
      height: '150px',
    },
    '@media (max-width: 1025px)': {
      marginTop: '50px',
    },
    '@media (max-width: 550px)': {
      marginTop: '50px',
      width: '120px',
      height: '120px',
    },
    '@media (max-width: 364px)': {
      marginTop: '150px',
    },
    '@media (max-width: 347px)': {
      width: '100px',
      height: '100px',
      marginTop: '150px',
    },
  },
}))

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props

  return (
    <div style={{ backgroundColor: colors.LandingPage_Background }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'white',
        }}
        {...rest}
      />
      <Parallax image={require('assets/img/buildings-banner.svg').default}>
        <Grid className={classes.content}>
          <div>
            <img
              src={require('assets/img/Icon.svg').default}
              alt="Something"
              className={classes.iconStyle}
            />
          </div>
          <h1 className={classes.content_title}>
            You can <span className={classes.content_title_bold}>bee</span>{' '}
            anywhere
            <span className={classes.content_title_bold}>,</span>
            <br /> anytime!
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '20px',
              marginTop: '20px',
            }}
          >
            <AppCard type="ios" />
            <AppCard type="android" />
          </div>
        </Grid>
      </Parallax>
    </div>
  )
}
