import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
/**
 *
 * @param open: boolean
 * @param desc: string
 * @param title: string
 * @param agree?: string
 * @param disagree?: string
 * @param onClose: function
 * @param onConfirm: function
 * @returns
 */
export default function ConfirmDialog({
  open,
  desc,
  title,
  agree,
  disagree,
  onClose,
  onConfirm,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {disagree ?? 'Disagree'}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {agree ?? 'Agree'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
