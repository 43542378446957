import {
  CONFIRM_BOOKING,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILED,
  CLEAR_BOOKING,
} from '../store/types'
import { RequestPushMsg } from '../other/NotificationFunctions'
import store from '../store/store'

export const clearBooking = () => (dispatch) => (firebase) => {
  dispatch({
    type: CLEAR_BOOKING,
    payload: null,
  })
}

export const addBooking = (bookingData) => (dispatch) => async (firebase) => {
  const { bookingRef, settingsRef, singleUserRef } = firebase

  dispatch({
    type: CONFIRM_BOOKING,
    payload: bookingData,
  })
  let pickUp = {
    lat: bookingData.pickup.coords.lat,
    lng: bookingData.pickup.coords.lng,
    add: bookingData.pickup.description,
  }
  let drop = {
    lat: bookingData.drop.coords.lat,
    lng: bookingData.drop.coords.lng,
    add: bookingData.drop.description,
  }

  let coords = [{ latitude: pickUp.lat, longitude: pickUp.lng }]
  if (bookingData.drop.waypointsStr) {
    bookingData.drop.waypointsStr.split('|').forEach((point) => {
      let lat = point.split(',')[0]
      let lng = point.split(',')[1]
      coords.push({ latitude: parseFloat(lat), longitude: parseFloat(lng) })
    })
  }
  coords.push({ latitude: drop.lat, longitude: drop.lng })

  var otp
  if (bookingData.settings.otp_secure)
    otp = Math.floor(Math.random() * 9000) + 1000
  else {
    otp = false
  }
  let today = new Date().getTime()

  const settingsdata = await settingsRef.once('value')
  const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const reference = [...Array(6)]
    .map((_) => c[~~(Math.random() * c.length)])
    .join('')
  const settings = settingsdata.val()

  let data = {
    carType: bookingData.carDetails.name,
    carImage: bookingData.carDetails.image,
    carTopViewImage: bookingData.carDetails.top_view_image,
    customer: bookingData.userDetails.uid,
    commission_type: bookingData.carDetails.convenience_fee_type,
    commission_rate: bookingData.carDetails.convenience_fees,
    reference: reference,
    customer_email: bookingData.userDetails.email,
    customer_name:
      bookingData.userDetails.firstName +
      ' ' +
      bookingData.userDetails.lastName,
    customer_contact: bookingData.userDetails.mobile
      ? bookingData.userDetails.mobile
      : ' ',
    customer_token: bookingData.userDetails.pushToken
      ? bookingData.userDetails.pushToken
      : ' ',
    customer_image: bookingData.userDetails.profile_image
      ? bookingData.userDetails.profile_image
      : '',
    drop: drop,
    pickup: pickUp,
    estimate: bookingData.estimate.estimateFare,
    fullPrice: bookingData.bookLater
      ? bookingData.estimate.estimateFare ?? null
      : null,
    estimateDistance: bookingData.estimate.estimateDistance,
    distance: bookingData.estimate.estimateDistance,
    estimateTime: bookingData.estimate.estimateTime,
    status: bookingData.status || 'NEW',
    bookLater: bookingData.bookLater,
    tripdate: bookingData.bookLater ? bookingData.tripdate : today,
    bookingDate: bookingData.bookLater ? bookingData.tripdate : today,
    otp: otp,
    booking_type_admin: bookingData.booking_type_admin,
    payment_mode: bookingData.payment_mode,
    auto_accept: bookingData?.auto_accept ?? false,
    partner_company: bookingData.partner_company ?? null,
    orderId: bookingData?.orderId ?? '',
    isDinersCard: bookingData?.isDinersCard ?? false,
    coords: coords,
    waypoints: bookingData.drop.waypoints ? bookingData.drop.waypoints : null,
    roundTrip: bookingData.roundTrip ? bookingData.roundTrip : null,
    tripInstructions: bookingData.tripInstructions
      ? bookingData.tripInstructions
      : null,
    trip_cost: bookingData.estimate.estimateFare,
    convenience_fees: bookingData.estimate.convenience_fees,
    driver_share: (
      parseFloat(bookingData.estimate.estimateFare) -
      parseFloat(bookingData.estimate.convenience_fees)
    ).toFixed(settings.decimal),
    paymentPacket: bookingData.paymentPacket ? bookingData.paymentPacket : null,
    requestedDrivers: bookingData.requestedDrivers
      ? bookingData.requestedDrivers
      : null,
    driverRecivedRequestInSec: new Date().getTime() / 1000,
    ...bookingData.instructionData,
  }

  if (bookingData.requestedDrivers) {
    const drivers = bookingData.requestedDrivers

    Object.keys(drivers).map((uid) => {
      singleUserRef(uid).once('value', (snapshot) => {
        if (snapshot.val()) {
          const pushToken = snapshot.val().pushToken
          const ios = snapshot.val().userPlatform === 'IOS' ? true : false
          RequestPushMsg(pushToken, {
            title:
              store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage
              .new_booking_notification,
            screen: 'DriverTrips',
            channelId: settings.CarHornRepeat ? 'bookings-repeat' : 'bookings',
            ios: ios,
          })(firebase)
        }
      })
      // firebase.database.ref('users/' + uid).update({
      //     driverActiveStatus: false,
      // })
      return drivers[uid]
    })
  }

  bookingRef
    .push(data)
    .then((res) => {
      var bookingKey = res.key
      dispatch({
        type: CONFIRM_BOOKING_SUCCESS,
        payload: {
          booking_id: bookingKey,
          mainData: {
            ...data,
            id: bookingKey,
          },
        },
      })
    })
    .catch((error) => {
      dispatch({
        type: CONFIRM_BOOKING_FAILED,
        payload: error.code + ': ' + error.message,
      })
    })
}

export const bookingUpdatePartial = (bookingData) => async (firebase) => {
  const { singleUserRef, settingsRef } = firebase

  const { id } = bookingData

  delete bookingData['id']
  firebase.database.ref('bookings/' + id).update(bookingData)

  const settingsdata = await settingsRef.once('value')

  const settings = settingsdata.val()
  if (bookingData.requestedDrivers) {
    const drivers = bookingData.requestedDrivers

    Object.keys(drivers).map((uid) => {
      if (drivers[uid]) {
        singleUserRef(uid).once('value', (snapshot) => {
          if (snapshot.val()) {
            const pushToken = snapshot.val().pushToken
            const ios = snapshot.val().userPlatform == 'IOS' ? true : false
            RequestPushMsg(pushToken, {
              title:
                store.getState().languagedata.defaultLanguage
                  .notification_title,
              msg: store.getState().languagedata.defaultLanguage
                .new_booking_notification,
              screen: 'DriverTrips',
              channelId: settings.CarHornRepeat
                ? 'bookings-repeat'
                : 'bookings',
              ios: ios,
            })(firebase)
          }
        })
      }
      return drivers[uid]
    })
  }
}

export const bookingUpdatePartialData = (bookingData) => async (firebase) => {
  const { id } = bookingData

  delete bookingData['id']

  firebase.database.ref('bookings/' + id).update(bookingData)
}

export const userUpdatePartial = (userData) => async (firebase) => {
  const { singleUserRef } = firebase
  const { id } = userData
  delete userData['id']
  singleUserRef(id).update(userData)
}

export const reduceWalletBalance =
  (uid, bookingId, minus) => async (firebase) => {
    if (!minus) return
    const { walletBalRef, walletHistoryRef, settingsRef } = firebase

    let details = {
      type: 'Debit',
      amount: minus,
      date: new Date().getTime(),
      txRef: bookingId,
    }
    const settingsdata = await settingsRef.once('value')
    const settings = settingsdata.val()
    const walletBalance = (await walletBalRef(uid).once('value')).val()
    walletBalRef(uid)
      .set(
        parseFloat(parseFloat(walletBalance - minus).toFixed(settings.decimal))
      )
      .then(() => {
        walletHistoryRef(uid).push(details)
      })
  }
