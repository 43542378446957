import React, { useState, useEffect, useContext, useRef } from 'react'
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux'
import CircularLoading from '../components/CircularLoading'
import { FirebaseContext } from 'common/src'
import { useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'
import { Modal, Grid, Typography, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    alignContent: 'center',
  },
  title: {
    color: '#000',
  },
  gridcontainer: {
    alignContent: 'center',
  },
  items: {
    margin: 0,
    width: '100%',
  },
  input: {
    fontSize: 18,
    color: '#000',
  },
  inputdimmed: {
    fontSize: 18,
    color: '#737373',
  },
  carphoto: {
    height: '18px',
    marginRight: '10px',
  },
  carphotoRtl: {
    height: '16px',
    marginLeft: '10px',
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: '100%',
  },
  inputRtl: {
    '& label': {
      right: 25,
      left: 'auto',
    },
    '& legend': {
      textAlign: 'right',
      marginRight: 18,
    },
  },
  rightRty: {
    '& legend': {
      marginRight: 30,
    },
  },
}))

export default function Users() {
  const { api } = useContext(FirebaseContext)
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const classes = useStyles()
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    fetchUsersOnce,
    fetchCompanies,
  } = api
  const settings = useSelector((state) => state.settingsdata.settings)
  const [data, setData] = useState([])
  const staticusers = useSelector((state) => state.usersdata.staticusers)
  const dispatch = useDispatch()
  const loaded = useRef(false)
  const [partnerCompanies, setPartnerCompanies] = useState([])
  const [walletHistoryModal, setWalletHistoryModal] = useState(false)
  const [walletHistoryData, setWalletHistoryData] = useState([])

  useEffect(() => {
    if (!staticusers) {
      dispatch(fetchUsersOnce())
    }
    dispatch(fetchUsersOnce())
  }, [dispatch, fetchUsersOnce, staticusers])

  useEffect(() => {
    if (staticusers) {
      const riderUsers =
        staticusers.filter((user) => user.usertype === 'rider') || []

      setData(riderUsers)
      loaded.current = true
    } else {
      setData([])
    }
  }, [staticusers])

  useEffect(() => {
    const effect = async () => {
      const companies = await fetchCompanies()
      setPartnerCompanies(companies)
    }
    effect()
  }, [fetchCompanies])

  const handleWalletHistoryModal = (rowData) => {
    const arrayOfObjects = rowData?.walletHistory
      ? Object.entries(rowData?.walletHistory).map(([key, value]) => ({
          id: key,
          ...value,
        }))
      : []

    setWalletHistoryData(arrayOfObjects)
    setWalletHistoryModal(true)
  }

  const columns = [
    {
      title: t('createdAt'),
      field: 'createdAt',
      editable: 'never',
      defaultSort: 'desc',
      render: (rowData) =>
        rowData.createdAt ? moment(rowData.createdAt).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('first_name'),
      field: 'firstName',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('last_name'),
      field: 'lastName',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('mobile'),
      field: 'mobile',
      editable: 'onAdd',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    },
    {
      title: t('partner_company'),
      field: 'partnerCompany',
      lookup: partnerCompanies.reduce((acc, curr) => {
        acc[curr.id] = curr.name
        return acc
      }, {}),
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    },
    {
      title: t('email'),
      field: 'email',
      editable: 'onAdd',
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.email : 'Hidden for Demo',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: t('profile_image'),
      field: 'profile_image',
      render: (rowData) =>
        rowData.profile_image ? (
          <img
            alt="Profile"
            src={rowData.profile_image}
            style={{ width: 50, borderRadius: '50%' }}
          />
        ) : null,
      editable: 'never',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('account_approve'),
      field: 'approved',
      type: 'boolean',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('mobileAppVersion'),
      field: 'mobileAppVersion',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: t('gender'),
      field: 'gender',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    {
      title: 'Front ID',
      field: 'frontIDImage',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
      render: (rowData) =>
        rowData?.frontIDImage && (
          <img
            alt="Front ID"
            src={rowData?.frontIDImage}
            style={{ width: 50 }}
          />
        ),
    },
    {
      title: 'Women Approved',
      field: 'womenApproved',
      type: 'boolean',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('wallet_balance'),
      field: 'walletBalance',
      type: 'numeric',
      editable: 'never',
      initialEditValue: 0,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'center' },
    },
    {
      title: t('signup_via_referral'),
      field: 'signupViaReferral',
      editable: 'never',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('referralId'),
      field: 'referralId',
      editable: 'never',
      initialEditValue: '',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
    },
    // {
    //   title: t('bankName'),
    //   field: 'bankName',
    //   hidden: settings.bank_fields === false ? true : false,
    //   initialEditValue: '',
    //   cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    // },
    // {
    //   title: t('bankCode'),
    //   field: 'bankCode',
    //   hidden: settings.bank_fields === false ? true : false,
    //   initialEditValue: '',
    //   cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    // },
    // {
    //   title: t('bankAccount'),
    //   field: 'bankAccount',
    //   hidden: settings.bank_fields === false ? true : false,
    //   initialEditValue: '',
    //   cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    // },
    {
      title: 'Wallet History',
      field: 'walletHistory',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'center' : 'left' },
      render: (rowData) => {
        return (
          rowData?.walletHistory && (
            <button onClick={() => handleWalletHistoryModal(rowData)}>
              Show deatils
            </button>
          )
        )
      },
    },
    {
      title: t('id'),
      field: 'id',
      hidden: settings.bank_fields === false ? true : false,
      initialEditValue: '',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    },
    // {
    //   title: t('bankName'),
    //   field: 'bankName',
    //   hidden: settings.bank_fields === false ? true : false,
    //   initialEditValue: '',
    //   cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    // },
    // {
    //   title: t('bankCode'),
    //   field: 'bankCode',
    //   hidden: settings.bank_fields === false ? true : false,
    //   initialEditValue: '',
    //   cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    // },
    // {
    //   title: t('bankAccount'),
    //   field: 'bankAccount',
    //   hidden: settings.bank_fields === false ? true : false,
    //   initialEditValue: '',
    //   cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'center' },
    // },
  ]

  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={walletHistoryModal}
        onClose={() => setWalletHistoryModal(false)}
        className={classes.modal}
        // container={() => rootRef.current}
      >
        <Grid container spacing={1} className={classes.paper}>
          <Typography
            component="h2"
            variant="h5"
            style={{ marginTop: 15, color: '#000' }}
          >
            Wallet History
          </Typography>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
              width: '400px',
              height: '400px',
              overflow: 'scroll',
            }}
          >
            {walletHistoryData.map((walletItem) => (
              <div
                key={walletItem.id}
                style={{
                  borderBottom: '1px solid gray',
                  paddingBottom: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginRight: '20px',
                  }}
                >
                  <div style={{ display: 'flex', gap: 5 }}>
                    <Typography style={{ marginTop: 15, color: '#000' }}>
                      Amount:
                    </Typography>

                    <Typography
                      style={{
                        marginTop: 15,
                        color: '#000',
                        fontWeight: 'bold',
                      }}
                    >
                      {walletItem.txRef === 'Cancellation Fee'
                        ? '-' + parseFloat(walletItem?.amount).toFixed(2)
                        : parseFloat(walletItem?.amount).toFixed(2)}
                    </Typography>
                  </div>

                  <div style={{ display: 'flex', gap: 5 }}>
                    <Typography style={{ marginTop: 15, color: '#000' }}>
                      Date:
                    </Typography>

                    <Typography
                      style={{
                        marginTop: 15,
                        color: '#000',
                        fontWeight: 'bold',
                      }}
                    >
                      {moment(walletItem.date).format('lll')}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginRight: '20px',
                  }}
                >
                  <div style={{ display: 'flex', gap: 5 }}>
                    <Typography style={{ marginTop: 15, color: '#000' }}>
                      Description:
                    </Typography>

                    <Typography
                      style={{
                        marginTop: 15,
                        color: '#000',
                        fontWeight: 'bold',
                      }}
                    >
                      {walletItem.txRef}
                    </Typography>
                  </div>
                </div>

                <div style={{ display: 'flex', gap: 5 }}>
                  <Typography style={{ marginTop: 15, color: '#000' }}>
                    Type:
                  </Typography>

                  <Typography
                    style={{
                      marginTop: 15,
                      color: '#000',
                      fontWeight: 'bold',
                    }}
                  >
                    {walletItem.type}
                  </Typography>
                </div>
              </div>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'center' }}
          >
            <Button
              onClick={() => setWalletHistoryModal(false)}
              variant="contained"
              color="primary"
            >
              {t('ok')}
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <MaterialTable
        title={t('riders')}
        columns={columns}
        style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
        data={data}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
          pageSize: 10,
        }}
        localization={{
          body: {
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            emptyDataSourceMessage: t('blank_message'),
            editRow: {
              deleteText: t('delete_message'),
              cancelTooltip: t('cancel'),
              saveTooltip: t('save'),
            },
          },
          toolbar: {
            searchPlaceholder: t('search'),
            exportTitle: t('export'),
          },
          header: {
            actions: t('actions'),
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
            labelRowsSelect: t('rows'),
            firstTooltip: t('first_page_tooltip'),
            previousTooltip: t('previous_page_tooltip'),
            nextTooltip: t('next_page_tooltip'),
            lastTooltip: t('last_page_tooltip'),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(t('user_exists'))
                    reject()
                  } else if (res.error) {
                    alert(t('email_or_mobile_issue'))
                    reject()
                  } else {
                    newData['regType'] = 'admin'
                    newData['usertype'] = 'rider'
                    newData['createdAt'] = new Date().toISOString()
                    const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                    const reference = [...Array(5)]
                      .map((_) => c[~~(Math.random() * c.length)])
                      .join('')
                    newData['referralId'] = reference
                    dispatch(addUser(newData))
                    dispatch(fetchUsersOnce())
                    resolve()
                  }
                })
              }, 600)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve()
                dispatch(editUser(oldData.id, newData))
                dispatch(fetchUsersOnce())
              }, 600)
            }),
          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve()
                    dispatch(deleteUser(oldData.id))
                    dispatch(fetchUsersOnce())
                  }, 600)
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve()
                    alert(t('demo_mode'))
                  }, 600)
                }),
        }}
      />
    </>
  )
}
