import React, { useState, useEffect, useContext } from 'react'
import CircularLoading from '../components/CircularLoading'
import { useTranslation } from 'react-i18next'
import MaterialTable from 'material-table'
import { FirebaseContext } from 'common/src'
import moment from 'moment/min/moment-with-locales'

export default function CustomersFeedbacks() {
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const { api } = useContext(FirebaseContext)
  const { fetchCustomersFeedbacks } = api
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getCustomersFeedbacks = async () => {
    try {
      setLoading(true)
      const response = await fetchCustomersFeedbacks()
      setData(response.reverse())

      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getCustomersFeedbacks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: t('Feedback Message'),
      field: 'feedbackMessage',
      editable: 'never',
    },
    {
      title: t('Rating'),
      field: 'rating',
      editable: 'never',
    },
    {
      title: t('customer_name'),
      field: 'customerName',
      editable: 'never',
    },
    {
      title: t('Customer Contact'),
      field: 'customerContact',
      editable: 'never',
    },
    {
      title: t('driver_name'),
      field: 'driverName',
      editable: 'never',
    },
    {
      title: t('Driver Contact'),
      field: 'driverContact',
      editable: 'never',
    },
    {
      title: t('Date'),
      field: 'date',
      editable: 'never',
      render: (rowData) =>
        rowData.date ? moment(rowData.date).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
  ]

  return loading ? (
    <CircularLoading />
  ) : (
    <>
      {data ? (
        <MaterialTable
          title={t('Customers Feedbacks')}
          columns={columns}
          style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
          data={data}
          options={{
            sorting: true,
            pageSize: 10,
          }}
          localization={{
            body: {
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              emptyDataSourceMessage: t('blank_message'),
              editRow: {
                deleteText: t('delete_message'),
                cancelTooltip: t('cancel'),
                saveTooltip: t('save'),
              },
            },
            toolbar: {
              searchPlaceholder: t('search'),
              exportTitle: t('export'),
            },
            header: {
              actions: t('actions'),
            },
            pagination: {
              labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
              labelRowsSelect: t('rows'),
              firstTooltip: t('first_page_tooltip'),
              previousTooltip: t('previous_page_tooltip'),
              nextTooltip: t('next_page_tooltip'),
              lastTooltip: t('last_page_tooltip'),
            },
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}
