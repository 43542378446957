const cardStyle = {
  cardWrapper: {
    width: '300px',
    height: '80px',
    color: 'yellow',
    borderRadius: '18px',
    border: '2px solid yellow',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '@media (max-width: 625px)': {
      width: '240px',
      height: '60px',
    },
    '@media (max-width: 510px)': {
      width: '200px',
      height: '50px',
    },
    '@media (max-width: 430px)': {
      width: '160px',
      height: '40px',
    },
  },
  img: {
    height: '65px',
    '@media (max-width: 625px)': {
      height: '45px',
    },
    '@media (max-width: 510px)': {
      height: '38px',
    },
    '@media (max-width: 430px)': {
      height: '30px',
      marginLeft: '8px',
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    alignItems: 'flex-start',
  },
  downloadText: {
    fontSize: '15px',
    '@media (max-width: 625px)': {
      fontSize: '14px',
    },
    '@media (max-width: 510px)': {
      fontSize: '13px',
    },
    '@media (max-width: 430px)': {
      fontSize: '11px',
    },
  },
  platform: {
    fontSize: '30px',
    fontWeight: '400',
    '@media (max-width: 625px)': {
      fontSize: '24px',
    },
    '@media (max-width: 510px)': {
      fontSize: '20px',
      marginBottom: '4px',
    },
    '@media (max-width: 430px)': {
      fontSize: '14px',
      padding: 0,
      margin: 0,
    },
  },
}

export default cardStyle
