import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_OUT,
  CLEAR_LOGIN_ERROR,
  UPDATE_USER_PROFILE,
  USER_NOT_REGISTERED,
  USER_DELETED,
  REQUEST_OTP,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  REQUEST_EMAIL_TOKEN,
  REQUEST_EMAIL_TOKEN_SUCCESS,
  REQUEST_EMAIL_TOKEN_FAILED,
} from '../store/types'

const INITIAL_STATE = {
  profile: null,
  loading: false,
  error: {
    flag: false,
    msg: null,
  },
  verificationId: null,
}

export const authreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true,
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
        error: {
          flag: false,
          msg: null,
        },
        verificationId: null,
      }
    case USER_NOT_REGISTERED:
      return {
        ...state,
        profile: action.payload,
        verificationId: null,
        loading: false,
      }
    case FETCH_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
        profile: null,
      }
    case USER_SIGN_IN:
      return {
        ...state,
        loading: true,
      }
    case USER_SIGN_IN_FAILED:
      return {
        ...state,
        profile: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      }
    case USER_SIGN_OUT:
      return INITIAL_STATE
    case USER_DELETED:
      return INITIAL_STATE
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
      }
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        verificationId: null,
        error: {
          flag: false,
          msg: null,
        },
        loading: false,
      }
    case REQUEST_OTP:
      return {
        ...state,
      }
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        verificationId: action.payload,
      }
    case REQUEST_OTP_FAILED:
      return {
        ...state,
        loading: false,
        verificationId: null,
        error: {
          flag: true,
          msg: action.payload,
        },
      }
    case REQUEST_EMAIL_TOKEN:
      return {
        ...state,
      }
    case REQUEST_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        emailToken: action.payload,
      }
    case REQUEST_EMAIL_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        emailToken: null,
        error: {
          flag: true,
          msg: action.payload,
        },
      }
    default:
      return state
  }
}
