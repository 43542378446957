import React, { useContext, useState } from 'react'
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import HomeIcon from '@material-ui/icons/Home'
import DashboardIcon from '@material-ui/icons/Dashboard'
import CarIcon from '@material-ui/icons/DirectionsCar'
import ExitIcon from '@material-ui/icons/ExitToApp'
import OfferIcon from '@material-ui/icons/LocalOffer'
import PeopleIcon from '@material-ui/icons/People'
import NotifyIcon from '@material-ui/icons/NotificationsActive'
import { FirebaseContext } from 'common/src'
import { colors } from '../components/Theme/WebTheme'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import { useTranslation } from 'react-i18next'
import SettingsIcon from '@material-ui/icons/Settings'
import LanguageIcon from '@material-ui/icons/Language'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MoneyIcon from '@material-ui/icons/Money'
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ViewListIcon from '@material-ui/icons/ViewList'
import ListAltIcon from '@material-ui/icons/ListAlt'
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import WomenIcon from '../assets/img/women.png'
import {
  Apartment,
  ExpandMore,
  Feedback,
  KeyboardArrowRight,
} from '@material-ui/icons'

function AppMenu() {
  const { api } = useContext(FirebaseContext)
  const { signOut } = api
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const LogOut = () => {
    dispatch(signOut())
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingRight: theme.spacing(4),
      fontSize: 14,
      paddingLeft: 30,
    },
  }))

  const classes = useStyles()

  const [collapse, setCollapse] = useState([false, false, false, false])

  const handleClick = (clickedIndex) => {
    if (collapse.includes(clickedIndex)) {
      const openCopy = collapse.filter((element) => {
        return element !== clickedIndex
      })
      setCollapse(openCopy)
    } else {
      const openCopy = [...collapse]
      openCopy.push(clickedIndex)
      setCollapse(openCopy)
    }
  }
  const arrowLeft = {
    position: 'absolute',
    left: 0,
  }
  const arrowRight = {
    position: 'absolute',
    right: 0,
  }

  let isAdmin = auth && auth.profile && auth.profile.usertype === 'admin'
  let isFleetAdmin =
    auth && auth.profile && auth.profile.usertype === 'fleetadmin'
  return (
    <div>
      <div
        style={{
          display: 'flex',
          backgroundColor: colors.AppMenu_Header,
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '120px',
            height: '120px',
          }}
          src={require('../assets/img/logo192x192.png').default}
          alt="Logo"
        />
      </div>
      <Divider />
      <MenuList>
        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('home')}</Typography>
        </MenuItem>
        {isAdmin || isFleetAdmin ? (
          <MenuItem component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('dashboard_text')}</Typography>
          </MenuItem>
        ) : null}
        <MenuItem component={Link} to="/bookings">
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('booking_history')}</Typography>
        </MenuItem>
        {isAdmin ? (
          <MenuItem component={Link} to="/advance-bookings">
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('Advanced Bookings')}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/customers-feedbacks">
            <ListItemIcon>
              <Feedback />
            </ListItemIcon>
            <Typography variant="inherit">
              {t('Customers Feedbacks')}
            </Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/partner-invoices">
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('Partner invoices')}</Typography>
          </MenuItem>
        ) : null}
        {isFleetAdmin ? (
          <MenuItem component={Link} to="/drivers">
            <ListItemIcon>
              <AirlineSeatReclineNormalIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('drivers')}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem divider component={Link} to="/request-women">
            <ListItemIcon>
              <img src={WomenIcon} alt="Women Icon" style={{ width: '22px' }} />
            </ListItemIcon>
            <Typography>{t('request_women')}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem
            button
            onClick={() => handleClick(1)}
            component={Link}
            to="/drivers"
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Typography>{t('users_title')}</Typography>

            {collapse.includes(1) && collapse.length > 0 ? (
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <ExpandMore />
              </ListItemIcon>
            ) : (
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowRight />
              </ListItemIcon>
            )}
          </MenuItem>
        ) : null}
        <Collapse in={collapse.includes(1)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/drivers"
              >
                <ListItemIcon>
                  <AirlineSeatReclineNormalIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('drivers')}</Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/riders"
              >
                <ListItemIcon>
                  <EmojiPeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('riders')}</Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/fleetadmins"
              >
                <ListItemIcon>
                  <GroupAddIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('fleetadmins')}</Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/alladmin"
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('alladmin')}</Typography>
              </ListItem>
            ) : null}
          </List>
        </Collapse>
        <Divider />
        {isAdmin ? (
          <MenuItem component={Link} to="/cartypes">
            <ListItemIcon>
              <CarIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('car_type')}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem divider component={Link} to="/cancelreasons">
            <ListItemIcon>
              <CancelScheduleSendIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              {t('cancellation_reasons')}
            </Typography>
          </MenuItem>
        ) : null}
        {isFleetAdmin ? (
          <MenuItem component={Link} to="/driverearning">
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('earning_reports')}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem
            button
            onClick={() => handleClick(2)}
            component={Link}
            to="/earningreports"
          >
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <Typography>{t('wallet_title')}</Typography>

            {collapse.includes(2) && collapse.length > 0 ? (
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <ExpandMore />
              </ListItemIcon>
            ) : (
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowRight />
              </ListItemIcon>
            )}
          </MenuItem>
        ) : null}
        <Collapse in={collapse.includes(2)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/earningreports"
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <Typography variant="inherit">
                  {t('earning_reports')}
                </Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/wallet-card-statistics"
              >
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <Typography variant="inherit">
                  {t('wallet_card_statistics')}
                </Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/driverearning"
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('driver_earning')}</Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/addtowallet"
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('add_to_wallet')}</Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/withdraws"
              >
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('withdraws')}</Typography>
              </ListItem>
            ) : null}
          </List>
        </Collapse>
        <Divider />

        {isAdmin ? (
          <MenuItem
            button
            onClick={() => handleClick(3)}
            component={Link}
            to="/appinfo"
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <Typography>{t('settings_title')}</Typography>

            {collapse.includes(3) && collapse.length > 0 ? (
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <ExpandMore />
              </ListItemIcon>
            ) : (
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowRight />
              </ListItemIcon>
            )}
          </MenuItem>
        ) : null}
        {/* <Collapse in={menuActive[3]} timeout="auto" unmountOnExit> */}
        <Collapse in={collapse.includes(3)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/appinfo"
              >
                <ListItemIcon>
                  <PhoneIphoneIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('app_info')}</Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/settings"
              >
                <ListItemIcon>
                  <SettingsApplicationsIcon />
                </ListItemIcon>
                <Typography variant="inherit">
                  {t('general_settings')}
                </Typography>
              </ListItem>
            ) : null}
            {isAdmin ? (
              <ListItem
                button
                className={classes.nested}
                component={Link}
                to="/languagesetting"
              >
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('language')}</Typography>
              </ListItem>
            ) : null}
          </List>
        </Collapse>
        <Divider />

        {isAdmin ? (
          <MenuItem component={Link} to="/promos">
            <ListItemIcon>
              <OfferIcon />
            </ListItemIcon>
            <Typography variant="inherit">{t('promo')}</Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/notifications">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">
              {t('push_notification_title')}
            </Typography>
          </MenuItem>
        ) : null}
        {isAdmin ? (
          <MenuItem component={Link} to="/companies">
            <ListItemIcon>
              <Apartment />
            </ListItemIcon>
            <Typography variant="inherit">{t('companies')}</Typography>
          </MenuItem>
        ) : null}

        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('profile')}</Typography>
        </MenuItem>
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('logout')}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  )
}

export default AppMenu
