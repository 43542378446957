import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FirebaseContext } from 'common/src'
import { useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'
import {
  AppBar,
  Badge,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import CircularLoading from 'components/CircularLoading'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import advanced from 'dayjs/plugin/advancedFormat'

import MaterialTable from 'material-table'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined'
import FastForwardOutlinedIcon from '@material-ui/icons/FastForwardOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import StatisticsCard from './components/StatisticsCard'
import DateTimePicker from './components/DateTimePicker'
import CheckboxComponent from './components/CheckboxComponent'
import CashIcon from '../../assets/img/payment-icons/cash.png'
import WalletIcon from '../../assets/img/payment-icons/wallet.png'
import CardIcon from '../../assets/img/payment-icons/card.png'
import PartnerIcon from '../../assets/img/payment-icons/partner-icon.png'
import ConfirmationDialogRaw from 'components/ConfirmationDialogRaw'
import ConfirmDialog from 'components/ConfirmDialog'
import TruncatedText from 'components/TruncatedText/TruncatedText'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import './AdvancedBookings.css'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const AdvancedBookings = (props) => {
  const { api } = useContext(FirebaseContext)
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const {
    fetchAllBookingsForAdmin,
    cancelBooking,
    getDriverById,
    bookingUpdatePartialData,
    userUpdatePartial,
    fetchApprovedDrivers,
  } = api
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const userdata = useSelector((state) => state.usersdata)
  const settings = useSelector((state) => state.settingsdata.settings)
  const [role, setRole] = useState(null)
  const bookinglistdata = useSelector((state) => state.bookinglistdata)
  const [users, setUsers] = useState(null)
  const [tab, setTab] = React.useState(0)
  const [filterData, setFilterData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    isRange: false,
    prevIsRange: false,
  })
  const [allData, setAllData] = useState([])
  const [oldData, setOldData] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [fromActiveData, setFromActiveData] = useState([])
  const [selectedBooking, setSelectedBooking] = useState()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openConfirmComplete, setOpenConfirmComplete] = useState(false)
  const [openConfirmStart, setOpenConfirmStart] = useState(false)
  const [openConfirmAccept, setOpenConfirmAccept] = useState(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false)
  const [statistics, setStatistics] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const sortedUsers = useMemo(() => {
    return users
      ? [...users].sort((a, b) => a.label.localeCompare(b.label))
      : []
  }, [users])

  const driverAssignHandler = async (bookingId, driverId) => {
    const driver = await getDriverById(driverId)

    const data = {
      id: bookingId,
      driver: driverId,
      driver_image: driver.profile_image ? driver.profile_image : '',
      driver_name: driver.firstName + ' ' + driver.lastName,
      driver_contact: driver.companyMobile || driver.mobile,
      driver_token: driver.pushToken,
      vehicle_number: driver.vehicleNumber,
      driverRating: driver.ratings ? driver.ratings.userrating : '0',
      fleetadmin: driver.fleetadmin ? driver.fleetadmin : '',
    }
    bookingUpdatePartialData(data)
  }

  const columns = [
    {
      title: t('vehicle_no'),
      field: 'vehicle_number',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
      render: (rowData) => {
        return (
          <div
            style={{
              display: 'flex',
              gap: '5px',
              width: '120px',
              alignItems: 'center',
            }}
          >
            {Number(rowData.distance) - Number(rowData.estimateDistance) >
            0.15 ? (
              <span style={{ color: 'red', fontSize: '3.5em' }}>•</span>
            ) : null}
            <span>{rowData.vehicle_number}</span>
          </div>
        )
      },
    },
    {
      title: t('trip_cost'),
      field: 'fullPrice',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
      render: (rowData) => {
        return (
          <div>
            {Number(rowData.distance) - Number(rowData.estimateDistance) >
            0.15 ? (
              <span style={{ color: 'red' }}>{rowData.estimate}</span>
            ) : (
              <span>{rowData.estimate}</span>
            )}
          </div>
        )
      },
    },
    {
      title: t('booking_date'),
      field: 'bookingDate',
      render: (rowData) =>
        rowData.bookingDate ? moment(rowData.bookingDate).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('customer_name'),
      field: 'customer_name',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
      render: (rowData) => (
        <Tooltip
          title={
            <div
              style={{ display: 'flex', flexDirection: 'column', padding: 5 }}
            >
              {/* TODO: Translation */}
              <span style={{ fontSize: 16, marginBottom: '10px' }}>
                Customer number: {rowData.customer_contact}
              </span>
              <span style={{ fontSize: 16 }}>
                Customer email: {rowData.customer_email}
              </span>
            </div>
          }
        >
          <span>{rowData.customer_name}</span>
        </Tooltip>
      ),
    },
    {
      title: t('assign_driver'),
      field: 'driver_name',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
      render:
        tab === 3
          ? (rowData) => {
              return (
                <Select
                  value={rowData.driver || ''}
                  placeholder="Assign driver"
                  onChange={(e) =>
                    driverAssignHandler(rowData.id, e.target.value)
                  }
                  style={{ minWidth: 200, maxWidth: 250 }}
                >
                  {sortedUsers.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.label}
                    </MenuItem>
                  ))}
                </Select>
              )
            }
          : undefined,
    },
    {
      title: t('payment_mode'),
      field: 'payment_mode',
      render: (rowData) =>
        rowData.payment_mode === 'cash' ? (
          <img alt="Cash" src={CashIcon} />
        ) : rowData.payment_mode === 'wallet' ? (
          <img alt="Wallet" src={WalletIcon} />
        ) : rowData.payment_mode === 'card' ? (
          <img alt="Card" src={CardIcon} />
        ) : rowData.payment_mode === 'partner' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <img
              alt="Partner"
              src={PartnerIcon}
              style={{ width: '30px', height: '30px' }}
            />
            <h5 style={{ fontWeight: '500' }}>Partner</h5>
          </div>
        ) : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('pickup_address'),
      field: 'pickupAddress',
      render: (rowData) => <TruncatedText text={rowData.pickupAddress} />,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('drop_address'),
      field: 'dropAddress',
      render: (rowData) => <TruncatedText text={rowData.dropAddress} />,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('booking_status'),
      field: 'status',
      render: (rowData) => <span>{t(rowData.status)}</span>,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('otp'),
      field: 'otp',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('car_type'),
      field: 'carType',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_start_time'),
      field: 'tripdate',
      render: (rowData) =>
        rowData.tripdate ? moment(rowData.tripdate).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_start_time'),
      field: 'trip_start_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_end_time'),
      field: 'trip_end_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('cancellation_reason'),
      field: 'reason',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    // { title: t('cancellationFee'), render: rowData => <span>{rowData.cancellationFee ? rowData.cancellationFee : (0).toFixed(settings.decimal)}</span>, cellStyle: { paddingLeft: isRTL === 'rtl' ? 40 : null } },
    {
      title: t('total_time'),
      field: 'total_trip_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('estimate_distance'),
      field: 'estimateDistance',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('distance'),
      field: 'distance',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    // { title: t('trip_cost_driver_share'), hidden: role === 'rider' ? true : false, field: 'driver_share', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('convenience_fee'), hidden: role === 'rider' ? true : false, field: 'convenience_fees', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('discount_ammount'), field: 'discount', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('Customer_paid'), field: 'customer_paid', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('payment_gateway'), field: 'gateway', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('cash_payment_amount'), field: 'cashPaymentAmount', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('card_payment_amount'), field: 'cardPaymentAmount', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    // { title: t('wallet_payment_amount'), field: 'usedWalletMoney', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('booking_id'), field: 'id' },
    {
      title: t('booking_ref'),
      field: 'reference',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
  ]

  const canceledBookingsColumns = [
    {
      title: t('vehicle_no'),
      field: 'vehicle_number',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_cost'),
      field: 'trip_cost',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('booking_date'),
      field: 'bookingDate',
      render: (rowData) =>
        rowData.bookingDate ? moment(rowData.bookingDate).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('customer_name'),
      field: 'customer_name',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
      render: (rowData) => (
        <Tooltip
          title={
            <div
              style={{ display: 'flex', flexDirection: 'column', padding: 5 }}
            >
              {/* TODO: Translation */}
              <span style={{ fontSize: 16, marginBottom: '10px' }}>
                Customer number: {rowData.customer_contact}
              </span>
              <span style={{ fontSize: 16 }}>
                Customer email: {rowData.customer_email}
              </span>
            </div>
          }
        >
          <span>{rowData.customer_name}</span>
        </Tooltip>
      ),
    },
    {
      title: t('assign_driver'),
      field: 'driver_name',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('payment_mode'),
      field: 'payment_mode',
      render: (rowData) =>
        rowData.payment_mode === 'cash' ? (
          <img alt="Cash" src={CashIcon} />
        ) : rowData.payment_mode === 'wallet' ? (
          <img alt="Wallet" src={WalletIcon} />
        ) : rowData.payment_mode === 'card' ? (
          <img alt="Card" src={CardIcon} />
        ) : rowData.payment_mode === 'partner' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <img
              alt="Partner"
              src={PartnerIcon}
              style={{ width: '30px', height: '30px' }}
            />
            <h5 style={{ fontWeight: '500' }}>Partner</h5>
          </div>
        ) : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('pickup_address'),
      field: 'pickup.add',
      render: (rowData) => <TruncatedText text={rowData.pickup.add} />,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('drop_address'),
      field: 'drop.add',
      render: (rowData) => <TruncatedText text={rowData.drop.add} />,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('booking_status'),
      field: 'status',
      render: (rowData) => <span>{t(rowData.status)}</span>,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('otp'),
      field: 'otp',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('car_type'),
      field: 'carType',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_start_time'),
      field: 'tripdate',
      render: (rowData) =>
        rowData.tripdate ? moment(rowData.tripdate).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_start_time'),
      field: 'trip_start_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_end_time'),
      field: 'trip_end_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('cancellation_reason'),
      field: 'reason',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('total_time'),
      field: 'total_trip_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('estimate_distance'),
      field: 'estimateDistance',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('distance'),
      field: 'distance',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    { title: t('booking_id'), field: 'id' },
    {
      title: t('booking_ref'),
      field: 'reference',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
  ]

  useEffect(() => {
    dispatch(fetchApprovedDrivers())
  }, [dispatch, fetchApprovedDrivers])

  useEffect(() => {
    if (auth.profile) {
      setRole(auth.profile.usertype)
    }
  }, [auth.profile])

  useEffect(() => {
    if (userdata.approvedDrivers) {
      let arr = []
      for (let i = 0; i < userdata.approvedDrivers.length; i++) {
        let user = userdata.approvedDrivers[i]
        arr.push({
          label:
            user.firstName + ' ' + user.lastName + ' (' + user.mobile + ')',
          id: user.id,
        })
      }
      setUsers(arr)
    }
  }, [userdata.approvedDrivers, userdata.approvedDrivers?.length])

  const handleChange = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue)
    }
  }

  const handleSelection = (event, value) => {
    // value will be the selected user object
    if (value) {
      setSelectedUserId(value.id)
    } else {
      setSelectedUserId(null)
    }
  }

  const loadBookingsAndMap = async (type, date, driverId) => {
    const response = await fetchAllBookingsForAdmin(type, date, driverId)
    return response
      ? Object.keys(response).map((key) => {
          return { ...response[key], id: key }
        })
      : []
  }

  function convertDate(inputDate) {
    if (!inputDate) return null
    const parts = inputDate.split('-')
    return `${parts[2]}-${parts[1]}-${parts[0]}`
  }

  function getDatesInRange(startDate, endDate) {
    const start = dayjs(convertDate(startDate))
    const end = dayjs(convertDate(endDate))

    let dates = []

    let current = start
    while (current.isBefore(end) || current.isSame(end)) {
      dates.push(current.format('DD-MM-YYYY'))
      current = current.add(1, 'day')
    }
    return dates
  }

  const fetchOldBookings = async () => {
    const dates = filterData.isRange
      ? getDatesInRange(filterData.startDate, filterData.endDate)
      : [filterData.startDate]
    let res = []
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i]
      if (tab === 1) {
        const resData = await loadBookingsAndMap(
          'COMPLETE',
          date,
          selectedUserId
        )
        if (!resData) continue
        res = [...res, ...resData]
      } else if (tab === 2) {
        const resData = await loadBookingsAndMap(
          'CANCELLED',
          date,
          selectedUserId
        )
        if (!resData) continue
        res = [...res, ...resData]
      } else {
        res = []
      }
    }
    setOldData(res)
  }
  useEffect(() => {
    if (tab === 1) {
      // let i = 0
      const stats = allData?.reduce((statistics, newBook) => {
        if (!statistics[newBook.driver]) {
          statistics[newBook.driver] = {
            driverLabel:
              newBook.driver_name + ' (' + newBook.driver_contact + ')',
            cash_count: 0,
            card_count: 0,
            wallet_count: 0,
            cash_amount: 0,
            card_amount: 0,
            wallet_amount: 0,
          }
        }
        let paymentMode = newBook.payment_mode ?? 'cash'
        if (paymentMode === 'partner') {
          paymentMode = 'wallet'
        }
        statistics[newBook.driver][`${paymentMode}_count`]++
        statistics[newBook.driver][`${paymentMode}_amount`] +=
          +newBook.fullPrice

        return statistics
      }, {})
      setStatistics(stats)
    } else {
      setStatistics({})
    }
  }, [allData, tab])

  const fetchFromActive = () => {
    if (bookinglistdata.bookings) {
      let data = []
      if (tab === 0) {
        data = bookinglistdata.bookings.filter(
          (booking) =>
            booking.status !== 'CANCELLED' &&
            booking.status !== 'COMPLETE' &&
            booking.status !== 'PAID' &&
            booking.status !== 'RESERVED'
        )
      } else if (tab === 1) {
        data = bookinglistdata.bookings?.filter((booking) => {
          // if (booking.status === 'COMPLETE' || booking.status === 'PAID')
          return booking.status === 'COMPLETE' || booking.status === 'PAID'
        })
      } else if (tab === 2) {
        data = bookinglistdata.bookings?.filter((booking) => {
          return (
            booking.status === 'CANCELLED' &&
            dayjs(booking.bookingDate).format('DD-MM-YYYY')
          )
        })
      } else if (tab === 3) {
        data = bookinglistdata.bookings?.filter(
          (booking) => booking.status === 'RESERVED'
        )
      }
      setFromActiveData(
        selectedUserId
          ? data.filter((booking) => {
              return booking.driver === selectedUserId
            })
          : data
      )
    } else {
      setFromActiveData([])
    }
  }

  useEffect(() => {
    fetchFromActive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookinglistdata.bookings])

  useEffect(() => {
    setFilterData((prev) => {
      return {
        ...prev,
        prevIsRange: prev.isRange,
      }
    })
    fetchFromActive()
    fetchOldBookings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  function isBookingDateInRange(bookingDate, startDateTime, endDateTime) {
    if (!startDateTime || !endDateTime) return true
    const bookingDateTime = dayjs.utc(bookingDate).tz('Europe/Skopje')
    if (!filterData.prevIsRange) {
      return bookingDateTime.format('DD-MM-YYYY') === filterData.startDate
    }

    return (
      bookingDateTime.isAfter(startDateTime) &&
      bookingDateTime.isBefore(endDateTime)
    )
  }

  useEffect(() => {
    const startDateTime = dayjs(
      `${convertDate(filterData.startDate)}T${filterData.startTime}`
    )
    const endDateTime = dayjs(
      `${convertDate(filterData.endDate)}T${filterData.endTime}`
    )
    const old = oldData.filter((booking) =>
      isBookingDateInRange(booking.bookingDate, startDateTime, endDateTime)
    )
    const newActive =
      tab === 0 || tab === 3
        ? fromActiveData
        : fromActiveData.filter((booking) =>
            isBookingDateInRange(
              booking.bookingDate,
              startDateTime,
              endDateTime
            )
          )
    setAllData(
      [...newActive, ...old].sort((a, b) => b.bookingDate - a.bookingDate)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldData, fromActiveData])

  const handleConfirmAccept = () => {
    if (settings.AllowCriticalEditsAdmin && role === 'admin') {
      bookingUpdatePartialData({
        id: selectedBooking.id,
        status: 'ACCEPTED',
      })
    } else {
      alert(t('demo_mode'))
    }
    setOpenConfirmAccept(false)
  }

  const handleConfirmStart = () => {
    if (settings.AllowCriticalEditsAdmin && role === 'admin') {
      bookingUpdatePartialData({
        id: selectedBooking.id,
        status: 'STARTED',
      })
    } else {
      alert(t('demo_mode'))
    }
    setOpenConfirmStart(false)
    setSelectedBooking(null)
  }

  const handleConfirmCancel = () => {
    if (settings.AllowCriticalEditsAdmin && role === 'admin') {
      if (
        selectedBooking?.status === 'NEW' ||
        selectedBooking?.status === 'ACCEPTED'
      ) {
        setSelectedBooking(selectedBooking)
        setOpenConfirm(true)
      } else {
        setTimeout(() => {
          dispatch(
            cancelBooking({
              reason: t('cancelled_incomplete_booking'),
              booking: selectedBooking,
              cancelledBy: 'Admin',
            })
          )
        }, 1500)
      }
    } else {
      alert(t('demo_mode'))
    }
    setOpenConfirmCancel(false)
  }

  const handleConfirmComplete = () => {
    if (settings.AllowCriticalEditsAdmin && role === 'admin') {
      if (
        selectedBooking &&
        fromActiveData.some((booking) => booking.id === selectedBooking.id)
      ) {
        bookingUpdatePartialData({
          id: selectedBooking.id,
          status: 'COMPLETE',
        })
        userUpdatePartial({
          id: selectedBooking.driver,
          // queue: false,
          driverActiveStatus: true,
        })
      }
      setOpenConfirmComplete(false)
      setSelectedBooking(null)
    } else {
      alert(t('demo_mode'))
    }

    setOpenConfirmComplete(false)
  }

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
          cancelledBy: role,
        })
      )
    }
    setOpenConfirm(false)
    setSelectedBooking(null)
  }

  const handleMenuItemClick = (actionType) => {
    switch (actionType) {
      case 'ACCEPTED':
        setOpenConfirmAccept(true)
        break
      case 'STARTED':
        setOpenConfirmStart(true)
        break
      case 'CANCELLED':
        setOpenConfirmCancel(true)
        break
      case 'COMPLETED':
        setOpenConfirmComplete(true)
        break
      default:
        break
    }
    handleClose() // Close the menu
  }

  const handleClick = (event, rowData) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setSelectedBooking(rowData)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          marginBottom: 5,
        }}
      >
        <DateTimePicker
          name="bookingStartDate"
          onChange={(date) => {
            setFilterData((prev) => ({
              ...prev,
              startDate: dayjs(date).format('DD-MM-YYYY'),
              startTime: dayjs(date).format('HH:mm:ss'),
            }))
          }}
          showTime
          label="start"
        />
        <DateTimePicker
          name="bookingEndDate"
          onChange={(date) => {
            setFilterData((prev) => ({
              ...prev,
              endDate: dayjs(date).format('DD-MM-YYYY'),
              endTime: dayjs(date).format('HH:mm:ss'),
            }))
          }}
          showTime
          label="end"
        />
        <CheckboxComponent
          label="Check in range"
          onChange={(checked) => {
            setFilterData({
              ...filterData,
              isRange: checked,
            })
          }}
        />
        <div style={{ width: 400 }}>
          <Autocomplete
            disablePortal
            id="drivers"
            options={users ?? []}
            getOptionLabel={(option) => option.label}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Driver" />}
            onChange={handleSelection}
          />
        </div>
        <button
          onClick={() => {
            setFilterData((prev) => {
              return {
                ...prev,
                prevIsRange: prev.isRange,
              }
            })
            fetchFromActive()
            fetchOldBookings()
          }}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 15px',
            marginLeft: '10px',
            cursor: 'pointer',
            width: '100px',
          }}
        >
          Submit
        </button>
      </div>
      <AppBar position="static">
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="ACTIVE" />
          <Tab label="COMPLETE" />
          <Tab label="CANCELLED" />

          <Tab
            label={
              tab !== 3 ? (
                <Badge
                  badgeContent={
                    bookinglistdata.bookings?.filter(
                      (booking) =>
                        booking.status === 'RESERVED' && !booking.driver
                    ).length
                  }
                  color="error"
                >
                  RESERVED
                </Badge>
              ) : (
                'RESERVED'
              )
            }
          />
        </Tabs>
      </AppBar>

      <MaterialTable
        title={t('booking_title')}
        columns={tab === 2 ? canceledBookingsColumns : columns}
        data={allData}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
          pageSize: 10, // initial page size
          pageSizeOptions: [10, 20, 50, 100, 200],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: t('search'),
            exportTitle: t('export'),
          },
          header: {
            actions: t('actions'),
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
            labelRowsSelect: t('rows'),
            firstTooltip: t('first_page_tooltip'),
            previousTooltip: t('previous_page_tooltip'),
            nextTooltip: t('next_page_tooltip'),
            lastTooltip: t('last_page_tooltip'),
          },
        }}
        actions={
          tab === 0 || tab === 3
            ? [
                (rowData) => ({
                  icon: () => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '5px 10px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                        border: '1px solid #ddd',
                        width: '150px', // Set a fixed width
                      }}
                      id={`status-change-button-${rowData.id}`}
                      aria-controls={open ? 'status-change-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(event) => handleClick(event, rowData)}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = '#e0e0e0')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = '#f5f5f5')
                      }
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          style={{
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            color: '#333',
                          }}
                        >
                          {rowData.status}
                        </Typography>
                      </div>
                      <ArrowDropDownIcon style={{ color: '#333' }} />
                    </div>
                  ),
                  iconProps: {
                    style: { color: 'green' },
                  },
                  disabled: false,
                }),
              ]
            : []
        }
      />

      <Menu
        className="custom-menu"
        id="status-change-menu"
        aria-labelledby={
          anchorEl ? `status-change-button-${selectedBooking?.id}` : undefined
        }
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          disabled={selectedBooking?.status === 'CANCELLED'}
          style={{ borderBottom: '.5px solid lightgrey' }}
          onClick={() => handleMenuItemClick('CANCELLED')}
        >
          <div style={{ display: 'flex', gap: '5px' }}>
            <CancelIcon color="error" /> Cancelled
          </div>
        </MenuItem>

        <MenuItem
          disabled={selectedBooking?.status === 'STARTED'}
          style={{ borderBottom: '.5px solid lightgrey' }}
          onClick={() => handleMenuItemClick('STARTED')}
        >
          <div style={{ display: 'flex', gap: '5px' }}>
            <FastForwardOutlinedIcon /> Started
          </div>
        </MenuItem>
        <MenuItem
          disabled={selectedBooking?.status === 'ACCEPTED'}
          style={{ borderBottom: '.5px solid lightgrey' }}
          onClick={() => handleMenuItemClick('ACCEPTED')}
        >
          <div style={{ display: 'flex', gap: '5px' }}>
            <CheckCircleOutlineIcon /> Accepted
          </div>
        </MenuItem>
        <MenuItem
          disabled={selectedBooking?.status === 'COMPLETED'}
          style={{ borderBottom: '.5px solid lightgrey' }}
          onClick={() => handleMenuItemClick('COMPLETED')}
        >
          <div style={{ display: 'flex', gap: '5px' }}>
            <DoneAllOutlinedIcon /> Completed
          </div>
        </MenuItem>
      </Menu>

      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={''}
      />
      <ConfirmDialog
        open={openConfirmStart}
        onClose={() => {
          setSelectedBooking(null)
          setOpenConfirmStart(false)
        }}
        onConfirm={handleConfirmStart}
        title="Confirm Start"
        desc="Are you sure you want to mark this booking as Started?"
      />
      <ConfirmDialog
        open={openConfirmAccept}
        onClose={() => {
          setSelectedBooking(null)
          setOpenConfirmAccept(false)
        }}
        onConfirm={handleConfirmAccept}
        title="Accept Booking"
        desc="Are you sure you want to accept this booking?"
      />
      <ConfirmDialog
        open={openConfirmCancel}
        onClose={() => {
          setSelectedBooking(null)
          setOpenConfirmCancel(false)
        }}
        onConfirm={handleConfirmCancel}
        title="Cancel Booking"
        desc="Are you sure you want to cancel this booking?"
      />
      <ConfirmDialog
        open={openConfirmComplete}
        onClose={() => {
          setSelectedBooking(null)
          setOpenConfirmComplete(false)
        }}
        onConfirm={handleConfirmComplete}
        title="Confirm Completion"
        desc="Are you sure you want to mark this booking as Completed?"
      />

      <StatisticsCard
        statistics={statistics}
        showTotalEarnings={tab === 1 && !selectedUserId}
        date={
          filterData.isRange
            ? `${filterData.startDate} ${filterData.startTime} >> ${filterData.endDate} ${filterData.endTime}`
            : filterData.startDate
        }
      />
    </div>
  )
}

export default AdvancedBookings
