import React from 'react'
import MoneyIcon from '@material-ui/icons/Money'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import PrintIcon from '@material-ui/icons/Print'
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'

const formatAmount = (amount) => {
  return parseFloat(amount).toFixed(2)
}
const amountStyle = {
  fontWeight: 'bold',
  fontSize: '1.2rem', // Larger font size
  color: '#4a90e2', // Adjust color to make it stand out
  marginLeft: '8px',
}
const calculateTotals = (statistics) => {
  let totalCash = 0,
    totalWallet = 0,
    totalCard = 0,
    totalBookings = 0
  Object.values(statistics).forEach((stat) => {
    totalCash += stat.cash_amount
    totalWallet += stat.wallet_amount
    totalCard += stat.card_amount
    totalBookings += stat.cash_count + stat.wallet_count + stat.card_count
  })
  return { totalCash, totalWallet, totalCard, totalBookings }
}

function PrintDiv(divid, title, stat) {
  const inputTaximeter = document.querySelector('#' + divid + ' #taximeter')
  const labelTaximeter = document.querySelector('#' + divid + ' #taximeter-label')
  const inputExpenses = document.querySelector('#' + divid + ' #expenses')
  const labelExpenses = document.querySelector('#' + divid + ' #expenses-label')

  inputTaximeter.style.display = 'none'
  inputExpenses.style.display = 'none'
  labelTaximeter.style.display = 'none'
  labelExpenses.style.display = 'none'
  let contentElement = document.getElementById(divid)

  var frame1 = document.createElement('iframe')
  frame1.name = 'frame1'
  frame1.style.position = 'absolute'
  frame1.style.top = '-1000000px'
  document.body.appendChild(frame1)
  var frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument
  frameDoc.document.open()
  frameDoc.document.write(`<html><head><title>${title}</title>`)
  frameDoc.document.write('</head><body>')
  frameDoc.document.write(`<h4>${title}</h4>`)

  // Create new div elements for the taximeter, expenses and total
  
  const taximeterDiv = document.createElement('div')
  taximeterDiv.style.display = 'flex'
  taximeterDiv.style.alignItems = 'center'
  taximeterDiv.style.marginTop = '8px'
  taximeterDiv.innerHTML = `Taximeter: ${inputTaximeter.value || 0} MKD`

  const expensesDiv = document.createElement('div')
  expensesDiv.style.display = 'flex'
  expensesDiv.style.alignItems = 'center'
  expensesDiv.style.marginTop = '8px'
  expensesDiv.innerHTML = `Expenses: ${inputExpenses.value || 0} MKD`

  const totalTaximeterAndTotal = document.createElement('div')
  totalTaximeterAndTotal.style.display = 'flex'
  totalTaximeterAndTotal.style.alignItems = 'center'
  totalTaximeterAndTotal.style.marginTop = '8px'
  totalTaximeterAndTotal.innerHTML = `Total cash: ${formatAmount(
    +(stat.cash_amount || 0) +
      (+inputTaximeter.value || 0) -
      (+inputExpenses.value || 0)
  )} MKD`

  // Insert the new elements into the contentElement
  const lastElement =
    contentElement.children[contentElement.children.length - 1]
  contentElement.insertBefore(taximeterDiv, lastElement)
  contentElement.insertBefore(expensesDiv, lastElement)
  contentElement.insertBefore(totalTaximeterAndTotal, lastElement)
  const lastElementValue = lastElement.children[0].children[0].innerHTML
  lastElement.children[0].children[0].innerHTML =
    lastElement.children[0].children[0].innerHTML.replace(
      /\((\d+(\.\d+)?)([^\d)]+)\)/,
      `(${formatAmount(
        stat.cash_amount +
          stat.card_amount +
          stat.wallet_amount +
          (+inputTaximeter.value || 0) -
          (+inputExpenses.value || 0)
      )}$3)`
    )

  var contents = contentElement.innerHTML
  inputTaximeter.style.display = ''
  inputExpenses.style.display = ''
  frameDoc.document.write(contents)
  frameDoc.document.write('</body></html>')
  frameDoc.document.close()
  setTimeout(function () {
    // window.frames["frame1"].focus();
    window.frames['frame1'].print()
    inputTaximeter.focus()
    inputExpenses.focus()
    contentElement.removeChild(taximeterDiv)
    contentElement.removeChild(expensesDiv)
    contentElement.removeChild(totalTaximeterAndTotal)
    lastElement.children[0].children[0].innerHTML = lastElementValue
    document.body.removeChild(frame1)
  }, 500)
  return false
}

function StatisticsCard({ statistics, showTotalEarnings, date }) {
  const totals = calculateTotals(statistics)
  return (
    <Grid container spacing={2}>
      {showTotalEarnings && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                Total Earnings and Bookings for the Day{' '}
                {date?.replaceAll('-', '.')}
              </Typography>
              <Typography variant="body1">
                Total Cash: {formatAmount(totals.totalCash)} MKD
              </Typography>
              <Typography variant="body1">
                Total Wallet: {formatAmount(totals.totalWallet)} MKD
              </Typography>
              <Typography variant="body1">
                Total Card: {formatAmount(totals.totalCard)} MKD
              </Typography>
              <Typography variant="h6" style={{ color: '#4CAF50' }}>
                Total Earnings:{' '}
                {formatAmount(
                  totals.totalCash + totals.totalWallet + totals.totalCard
                )}{' '}
                MKD
              </Typography>
              <Typography variant="h6" style={{ color: '#FF9800' }}>
                Total Bookings: {totals.totalBookings}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      {Object.keys(statistics).map((driverId) => {
        const stat = statistics[driverId]
        return (
          <Grid item xs={12} sm={6} md={4} key={driverId}>
            <Card variant="outlined">
              <CardContent id={driverId}>
                <Typography variant="h6" component="div">
                  {stat.driverLabel}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <MoneyIcon color="primary" height={30} />
                  <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    Cash Transactions: {stat.cash_count}{' '}
                    <span style={amountStyle}>
                      ({formatAmount(stat.cash_amount)} MKD)
                    </span>
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <CreditCardIcon color="primary" height={30} />
                  <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    Card Transactions: {stat.card_count}{' '}
                    <span style={amountStyle}>
                      ({formatAmount(stat.card_amount)} MKD)
                    </span>
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <AccountBalanceWalletIcon color="primary" height={30} />
                  <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    Wallet Transactions: {stat.wallet_count}{' '}
                    <span style={amountStyle}>
                      ({formatAmount(stat.wallet_amount)} MKD)
                    </span>
                  </Typography>
                </div>

                <div style={{ marginBottom: 10 }}>
                  <label id="taximeter-label" style={{ color: 'black', marginRight: 10 }}>
                    Taximeter:
                  </label>
                  <input id="taximeter" type="text" />
                </div>

                <div>
                  <label id="expenses-label" style={{ color: 'black', marginRight: 10 }}>
                    Expenses:
                  </label>
                  <input id="expenses" type="text" />
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <Typography variant="body1" style={{ marginLeft: '8px' }}>
                    Total:{' '}
                    <span style={amountStyle}>
                      (
                      {formatAmount(
                        stat.cash_amount + stat.card_amount + stat.wallet_amount
                      )}{' '}
                      MKD)
                    </span>
                  </Typography>
                </div>
              </CardContent>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  style={{ marginLeft: 'auto' }}
                  onClick={() =>
                    PrintDiv(driverId, date?.replaceAll('-', '.'), stat)
                  }
                >
                  <PrintIcon />
                </IconButton>
              </div>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default StatisticsCard
