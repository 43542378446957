import React, { createContext } from 'react'
import app from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import store from './store/store'

import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  initializeAuth,
  OAuthProvider,
  PhoneAuthProvider,
  RecaptchaVerifier,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  signInWithPhoneNumber,
} from 'firebase/auth'

import {
  fetchUser,
  mainSignUp,
  mobileSignIn,
  facebookSignIn,
  appleSignIn,
  signOut,
  updateProfile,
  clearLoginError,
  updatePushToken,
  updateImage,
  requestPhoneOtpDevice,
  deleteUser,
  validateReferer,
  checkUserExists,
  fetchProfile,
  updateWebProfileImage,
  requestEmailOtp,
  verifyEmailOtp,
  verifyPhoneAndPassword,
  readProfile,
  checkUserExistsAndHasPassword,
  removeUserPasswordAndPhoneVerification,
  monitorProfileChanges,
} from './actions/authactions'
import {
  addBooking,
  bookingUpdatePartial,
  bookingUpdatePartialData,
  clearBooking,
  reduceWalletBalance,
  userUpdatePartial,
} from './actions/bookingactions'
import {
  fetchBookings,
  updateBooking,
  cancelBooking,
  updateBookingImage,
  fetchBookingsListByStatus,
  fetchAllBookingsForAdmin,
  loadDriverEarnings,
  fetchAllBookingsForPartner,
} from './actions/bookinglistactions'
import {
  fetchCancelReasons,
  editCancellationReason,
} from './actions/cancelreasonactions'
import { fetchCarTypes, editCarType } from './actions/cartypeactions'
import { getEstimate, clearEstimate } from './actions/estimateactions'
import { fetchDriverEarnings } from './actions/driverearningaction'
import { fetchEarningsReport } from './actions/earningreportsaction'
import {
  fetchUserNotifications,
  fetchNotifications,
  editNotifications,
  sendNotification,
} from './actions/notificationactions'
import {
  fetchPromos,
  fetchPromoRoutes,
  editPromo,
} from './actions/promoactions'
import {
  addUser,
  fetchUsers,
  fetchUsersOnce,
  fetchDrivers,
  fetchApprovedDrivers,
  fetchActiveDrivers,
  editUser,
  updateDocumentsImage,
  fetchCompanies,
  getCompanyById,
  getSavedLocationsForUser,
  saveLocationToUser,
  saveCustomerFeedback,
  fetchCustomersFeedbacks,
  deleteLocationFromUser,
  checkPartnerTimes,
  getDriverById,
  getUsersByType,
} from './actions/usersactions'
import {
  fetchSettings,
  editSettings,
  clearSettingsViewError,
} from './actions/settingsactions'
import {
  fetchPaymentMethods,
  addToWallet,
  updateWalletBalance,
  clearMessage,
  fetchWalletCardHistory,
} from './actions/paymentactions'
import {
  updateTripPickup,
  updateTripDrop,
  updateTripCar,
  updatSelPointType,
  clearTripPoints,
} from './actions/tripactions'
import {
  fetchTasks,
  acceptTask,
  cancelTask,
  resetTasks,
} from './actions/taskactions'
import {
  fetchBookingLocations,
  stopLocationFetch,
} from './actions/locationactions'
import {
  fetchChatMessages,
  sendMessage,
  stopFetchMessages,
} from './actions/chatactions'
import { fetchWithdraws, completeWithdraw } from './actions/withdrawactions'

import { MinutesPassed, GetDateString } from './other/DateFunctions'
import {
  fetchPlacesAutocomplete,
  fetchCoordsfromPlace,
  fetchAddressfromCoords,
  getDistanceMatrix,
  getDirectionsApi,
} from './other/GoogleAPIFunctions'
import { GetDistance, GetTripDistance } from './other/GeoFunctions'
import { countries } from './other/GetCountries'
import { fetchLanguages, editLanguage } from './actions/languageactions'
import { RequestPushMsg } from './other/NotificationFunctions'
import { getReactNativePersistence } from './config/react-native-persistance'

const FirebaseContext = createContext(null)

const createFullStructure = (app, config, appcat, auth) => {
  // if (typeof document !== 'undefined') {
  //   app.auth().setPersistence({
  //     persistence: browserLocalPersistence,
  //     popupRedirectResolver: browserPopupRedirectResolver,
  //   })
  // } else {
  //   app.auth().setPersistence(getReactNativePersistence(AsyncStorage))
  // }

  return {
    app: app,
    config: config,
    appcat: appcat,
    database: app.database(),
    auth: auth,
    storage: app.storage(),
    authRef: getAuth(),
    facebookProvider: new FacebookAuthProvider(),
    googleProvider: new GoogleAuthProvider(),
    appleProvider: new OAuthProvider('apple.com'),
    phoneProvider: new PhoneAuthProvider(auth),
    RecaptchaVerifier: RecaptchaVerifier,
    signInWithPhoneNumber: signInWithPhoneNumber,
    captchaGenerator: (element) => new RecaptchaVerifier(element),
    facebookCredential: (token) => FacebookAuthProvider.credential(token),
    googleCredential: (idToken) => GoogleAuthProvider.credential(idToken),
    mobileAuthCredential: (verificationId, code) =>
      PhoneAuthProvider.credential(verificationId, code),
    usersRef: app.database().ref('users'),
    bookingRef: app.database().ref('bookings'),
    cancelreasonRef: app.database().ref('cancel_reason'),
    settingsRef: app.database().ref('settings'),
    carTypesRef: app.database().ref('cartypes'),
    carTypesEditRef: (id) => app.database().ref('cartypes/' + id),
    carDocImage: (id) => app.storage().ref(`cartypes/${id}_image`),
    carDocImage2: (id) => app.storage().ref(`cartypes/${id}_top_view_image`),
    promoRef: app.database().ref('promos'),
    promoEditRef: (id) => app.database().ref('promos/' + id),
    notifyRef: app.database().ref('notifications/'),
    walletCreditHistoryRef: app.database().ref('walletCreditHistory/'),
    notifyEditRef: (id) => app.database().ref('notifications/' + id),
    singleUserRef: (uid) => app.database().ref('users/' + uid),
    profileImageRef: (uid) => app.storage().ref(`users/${uid}/profileImage`),
    bookingImageRef: (bookingId, imageType) =>
      app.storage().ref(`bookings/${bookingId}/${imageType}`),
    driverDocsRef: (uid) => app.storage().ref(`users/${uid}/license`),
    carImageRef: (uid) => app.storage().ref(`users/${uid}/car`),
    frontIDImageRef: (uid) => app.storage().ref(`users/${uid}/frontID`),
    backIDImageRef: (uid) => app.storage().ref(`users/${uid}/backID`),
    taxiLicenseImageRef: (uid) => app.storage().ref(`users/${uid}/taxiLicense`),
    drivingLicenseImageRef: (uid) =>
      app.storage().ref(`users/${uid}/drivingLicense`),
    driverImageRef: (uid) => app.storage().ref(`users/${uid}/driverHealth`),
    vehicleInsuranceImageRef: (uid) =>
      app.storage().ref(`users/${uid}/vehicleInsurance`),
    singleBookingRef: (bookingKey) =>
      app.database().ref('bookings/' + bookingKey),
    requestedDriversRef: (bookingKey) =>
      app.database().ref('bookings/' + bookingKey + '/requestedDrivers'),
    walletBalRef: (uid) =>
      app.database().ref('users/' + uid + '/walletBalance'),
    walletHistoryRef: (uid) =>
      app.database().ref('users/' + uid + '/walletHistory'),
    referralIdRef: (referralId) =>
      app
        .database()
        .ref('users')
        .orderByChild('referralId')
        .equalTo(referralId),
    trackingRef: (bookingId) => app.database().ref('tracking/' + bookingId),
    tasksRef: () =>
      app.database().ref('bookings').orderByChild('status').equalTo('NEW'),
    singleTaskRef: (uid, bookingId) =>
      app.database().ref('bookings/' + bookingId + '/requestedDrivers/' + uid),
    bookingListRef: (uid, role) =>
      role === 'rider'
        ? app.database().ref('bookings').orderByChild('customer').equalTo(uid)
        : role === 'driver'
        ? app.database().ref('bookings').orderByChild('driver').equalTo(uid)
        : role === 'fleetadmin'
        ? app.database().ref('bookings').orderByChild('fleetadmin').equalTo(uid)
        : app.database().ref('bookings'),
    chatRef: (bookingId) =>
      app.database().ref('chats/' + bookingId + '/messages'),
    withdrawRef: app.database().ref('withdraws/'),
    languagesRef: app.database().ref('languages'),
    languagesEditRef: (id) => app.database().ref('languages/' + id),
  }
}

const FirebaseProvider = ({ config, appcat, children, AsyncStorage }) => {
  let firebase = {
    app: null,
    database: null,
    auth: null,
    storage: null,
  }

  let auth

  if (app.apps && app.apps.length === 0) {
    const fireApp = app.initializeApp(config)
    if (typeof document !== 'undefined') {
      auth = initializeAuth(fireApp, {
        persistence: browserLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      })
    } else {
      auth = initializeAuth(fireApp, {
        persistence: getReactNativePersistence(AsyncStorage),
      })
    }

    firebase = createFullStructure(app, config, appcat, auth)
  } else {
    auth = getAuth()
    firebase = createFullStructure(app, config, appcat, auth)
  }
  firebase.api = {
    MinutesPassed: MinutesPassed,
    GetDateString: GetDateString,

    fetchPlacesAutocomplete: (searchKeyword) =>
      fetchPlacesAutocomplete(searchKeyword)(firebase),
    fetchCoordsfromPlace: (place_id) =>
      fetchCoordsfromPlace(place_id)(firebase),
    fetchAddressfromCoords: (latlng) =>
      fetchAddressfromCoords(latlng)(firebase),
    getDistanceMatrix: (startLoc, destLoc) =>
      getDistanceMatrix(startLoc, destLoc)(firebase),
    getDirectionsApi: (startLoc, destLoc, waypoints) =>
      getDirectionsApi(startLoc, destLoc, waypoints)(firebase),

    RequestPushMsg: (token, data) => RequestPushMsg(token, data)(firebase),

    countries: countries,
    GetDistance: GetDistance,
    GetTripDistance: GetTripDistance,
    saveUserLocation: (uid, location) =>
      app
        .database()
        .ref('users/' + uid + '/location')
        .set(location),
    saveTracking: (bookingId, location) =>
      app
        .database()
        .ref('tracking/' + bookingId)
        .push(location),

    saveUserNotification: (uid, notification) =>
      app
        .database()
        .ref('users/' + uid + '/notifications')
        .push(notification),

    fetchUserNotifications: () => (dispatch) =>
      fetchUserNotifications()(dispatch)(firebase),

    validateReferer: (referralId) => validateReferer(referralId)(firebase),
    checkUserExists: (regData) => checkUserExists(regData)(firebase),
    bookingUpdatePartial: (bookingData) =>
      bookingUpdatePartial(bookingData)(firebase),
    bookingUpdatePartialData: (bookingData) =>
      bookingUpdatePartialData(bookingData)(firebase),
    userUpdatePartial: (userData) => userUpdatePartial(userData)(firebase),
    reduceWalletBalance: (uid, bookingId, minus) =>
      reduceWalletBalance(uid, bookingId, minus)(firebase),
    fetchUser: () => (dispatch) => fetchUser()(dispatch)(firebase),
    requestEmailOtp: (email) => (dispatch) =>
      requestEmailOtp(email)(dispatch)(firebase),
    removeUserPasswordAndPhoneVerification: (phoneNumber) =>
      removeUserPasswordAndPhoneVerification(phoneNumber)(firebase),
    checkUserExistsAndHasPassword: (phoneNumber) =>
      checkUserExistsAndHasPassword(phoneNumber)(firebase),
    verifyEmailOtp: (email, otp) => (dispatch) =>
      verifyEmailOtp(email, otp)(dispatch)(firebase),
    mobileSignIn: (verficationId, code) => (dispatch) =>
      mobileSignIn(verficationId, code)(dispatch)(firebase),
    verifyPhoneAndPassword: (verficationId, code) => (dispatch) =>
      verifyPhoneAndPassword(verficationId, code)(dispatch)(firebase),
    facebookSignIn: (token) => (dispatch) =>
      facebookSignIn(token)(dispatch)(firebase),
    appleSignIn: (credentialData) => (dispatch) =>
      appleSignIn(credentialData)(dispatch)(firebase),
    mainSignUp: (data) => mainSignUp(data)(firebase),
    monitorProfileChanges: () => (dispatch) =>
      monitorProfileChanges()(dispatch)(firebase),
    signOut: () => (dispatch) => signOut()(dispatch)(firebase),
    updateProfile: (userAuthData, updateData) => (dispatch) =>
      updateProfile(userAuthData, updateData)(dispatch)(firebase),
    fetchProfile: () => (dispatch) => fetchProfile()(dispatch)(firebase),
    clearLoginError: () => (dispatch) => clearLoginError()(dispatch)(firebase),
    addBooking: (bookingData) => (dispatch) =>
      addBooking(bookingData)(dispatch)(firebase),
    clearBooking: () => (dispatch) => clearBooking()(dispatch)(firebase),
    fetchBookings: (uid, role) => (dispatch) =>
      fetchBookings(uid, role)(dispatch)(firebase),
    fetchAllBookingsForAdmin: (type, date, driverId) =>
      fetchAllBookingsForAdmin(type, date, driverId)(firebase),
    fetchAllBookingsForPartner: (date, partnerId) =>
      fetchAllBookingsForPartner(date, partnerId)(firebase),
    fetchBookingsListByStatus: (uid, role, status, date) =>
      fetchBookingsListByStatus(uid, role, status, date)(firebase),
    loadDriverEarnings: (uid, dates) =>
      loadDriverEarnings(uid, dates)(firebase),
    updateBooking: (booking) => (dispatch) =>
      updateBooking(booking)(dispatch)(firebase),
    cancelBooking: (data) => (dispatch) =>
      cancelBooking(data)(dispatch)(firebase),
    fetchCancelReasons: () => (dispatch) =>
      fetchCancelReasons()(dispatch)(firebase),
    editCancellationReason: (reasons, method) => (dispatch) =>
      editCancellationReason(reasons, method)(dispatch)(firebase),
    fetchCarTypes: () => (dispatch) => fetchCarTypes()(dispatch)(firebase),
    editCarType: (data, method) => (dispatch) =>
      editCarType(data, method)(dispatch)(firebase),
    getEstimate: (bookingData) => (dispatch) =>
      getEstimate(bookingData)(dispatch)(firebase),
    clearEstimate: () => (dispatch) => clearEstimate()(dispatch)(firebase),
    fetchSettings: () => (dispatch) => fetchSettings()(dispatch)(firebase),
    editSettings: (settings) => (dispatch) =>
      editSettings(settings)(dispatch)(firebase),
    clearSettingsViewError: () => (dispatch) =>
      clearSettingsViewError()(dispatch)(firebase),
    fetchDriverEarnings: (uid, role) => (dispatch) =>
      fetchDriverEarnings(uid, role)(dispatch)(firebase),
    fetchEarningsReport: () => (dispatch) =>
      fetchEarningsReport()(dispatch)(firebase),
    fetchNotifications: () => (dispatch) =>
      fetchNotifications()(dispatch)(firebase),
    editNotifications: (notifications, method) => (dispatch) =>
      editNotifications(notifications, method)(dispatch)(firebase),
    sendNotification: (notification) => (dispatch) =>
      sendNotification(notification)(dispatch)(firebase),
    fetchPromos: () => (dispatch) => fetchPromos()(dispatch)(firebase),
    fetchPromoRoutes: () => fetchPromoRoutes()(firebase),
    editPromo: (data, method) => (dispatch) =>
      editPromo(data, method)(dispatch)(firebase),
    fetchUsers: () => (dispatch) => fetchUsers()(dispatch)(firebase),
    fetchApprovedDrivers: () => (dispatch) =>
      fetchApprovedDrivers()(dispatch)(firebase),
    fetchUsersOnce: () => (dispatch) => fetchUsersOnce()(dispatch)(firebase),
    fetchWalletCardHistory: () => (dispatch) =>
      fetchWalletCardHistory()(dispatch)(firebase),
    fetchDrivers: () => (dispatch) => fetchDrivers()(dispatch)(firebase),
    fetchActiveDrivers: () => (dispatch) =>
      fetchActiveDrivers()(dispatch)(firebase),
    addUser: (userdata) => (dispatch) => addUser(userdata)(dispatch)(firebase),
    editUser: (id, user) => (dispatch) =>
      editUser(id, user)(dispatch)(firebase),
    updatePushToken: (uid, token, platform) =>
      updatePushToken(uid, token, platform)(firebase),
    updateImage: (userAuthData, imageBlob, type) => (dispatch) =>
      updateImage(userAuthData, imageBlob, type)(dispatch)(firebase),
    requestPhoneOtpDevice: (verificationId) => (dispatch) =>
      requestPhoneOtpDevice(verificationId)(dispatch),
    deleteUser: (uid) => (dispatch) => deleteUser(uid)(dispatch)(firebase),
    fetchPaymentMethods: () => (dispatch) =>
      fetchPaymentMethods()(dispatch)(firebase),
    addToWallet: (uid, amount, orderId, paymentType) => (dispatch) =>
      addToWallet(uid, amount, orderId, paymentType)(dispatch)(firebase),
    updateWalletBalance: (balance, details) => (dispatch) =>
      updateWalletBalance(balance, details)(dispatch)(firebase),
    clearMessage: () => (dispatch) => clearMessage()(dispatch)(firebase),
    updateTripPickup: (pickupAddress) => (dispatch) =>
      updateTripPickup(pickupAddress)(dispatch)(firebase),
    updateTripDrop: (dropAddress) => (dispatch) =>
      updateTripDrop(dropAddress)(dispatch)(firebase),
    updateTripCar: (selectedCar) => (dispatch) =>
      updateTripCar(selectedCar)(dispatch)(firebase),
    updatSelPointType: (selection) => (dispatch) =>
      updatSelPointType(selection)(dispatch)(firebase),
    clearTripPoints: () => (dispatch) => clearTripPoints()(dispatch)(firebase),
    fetchTasks: () => (dispatch) => fetchTasks()(dispatch)(firebase),
    acceptTask: (userAuthData, task) => (dispatch) =>
      acceptTask(userAuthData, task)(dispatch)(firebase),
    cancelTask: (bookingId) => (dispatch) =>
      cancelTask(bookingId)(dispatch)(firebase),
    resetTasks: () => (dispatch) => resetTasks()(dispatch),
    fetchBookingLocations: (bookingId) => (dispatch) =>
      fetchBookingLocations(bookingId)(dispatch)(firebase),
    stopLocationFetch: (bookingId) => (dispatch) =>
      stopLocationFetch(bookingId)(dispatch)(firebase),
    fetchChatMessages: (bookingId) => (dispatch) =>
      fetchChatMessages(bookingId)(dispatch)(firebase),
    sendMessage: (data) => (dispatch) => sendMessage(data)(dispatch)(firebase),
    stopFetchMessages: (bookingId) => (dispatch) =>
      stopFetchMessages(bookingId)(dispatch)(firebase),
    fetchWithdraws: () => (dispatch) => fetchWithdraws()(dispatch)(firebase),
    completeWithdraw: (entry) => (dispatch) =>
      completeWithdraw(entry)(dispatch)(firebase),
    updateBookingImage: (booking, imageType, imageBlob) => (dispatch) =>
      updateBookingImage(booking, imageType, imageBlob)(dispatch)(firebase),
    editLanguage: (data, method) => (dispatch) =>
      editLanguage(data, method)(dispatch)(firebase),
    fetchLanguages: () => (dispatch) => fetchLanguages()(dispatch)(firebase),
    updateDocumentsImage: (user, imageBlob, docType) => (dispatch) =>
      updateDocumentsImage(user, imageBlob, docType)(dispatch)(firebase),
    updateWebProfileImage: (userAuthData, imageBlob) => (dispatch) =>
      updateWebProfileImage(userAuthData, imageBlob)(dispatch)(firebase),
    readProfile: () => readProfile()(firebase),
    fetchCompanies: () => fetchCompanies(firebase),
    getCompanyById: (id) => getCompanyById(id)(firebase),
    getDriverById: (id) => getDriverById(id)(firebase),
    getUsersByType: (type) => getUsersByType(type)(firebase),
    getSavedLocationsForUser: (userId, type) =>
      getSavedLocationsForUser(userId, type)(firebase),
    saveLocationToUser: (userId, data) =>
      saveLocationToUser(userId, data)(firebase),
    saveCustomerFeedback: (data) => saveCustomerFeedback(data)(firebase),
    fetchCustomersFeedbacks: () => fetchCustomersFeedbacks(firebase),
    deleteLocationFromUser: (userId, locationId) =>
      deleteLocationFromUser(userId, locationId)(firebase),
    checkPartnerTimes: (companyId) => checkPartnerTimes(companyId)(firebase),
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseContext, FirebaseProvider, store }
