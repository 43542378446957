import React, { useState, useEffect, useContext } from 'react'

import { FirebaseContext } from 'common/src'
import { useTranslation } from 'react-i18next'
import moment from 'moment/min/moment-with-locales'
import { AppBar, Box, Button, TextField, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import advanced from 'dayjs/plugin/advancedFormat'

import Autocomplete from '@material-ui/lab/Autocomplete'
import DateTimePicker from './components/DateTimePicker'
import CashIcon from '../../assets/img/payment-icons/cash.png'
import WalletIcon from '../../assets/img/payment-icons/wallet.png'
import CardIcon from '../../assets/img/payment-icons/card.png'
import PartnerIcon from '../../assets/img/payment-icons/partner-icon.png'
import MaterialTable from 'material-table'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const PrintInvoice = (props) => {
  const [partnerCompanies, setPartnerCompanies] = useState([])

  const { api } = useContext(FirebaseContext)
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const { fetchAllBookingsForPartner, fetchCompanies } = api

  useEffect(() => {
    const effect = async () => {
      const companies = await fetchCompanies()
      setPartnerCompanies(companies)
    }
    effect()
  }, [fetchCompanies])

  function printInvoice() {
    const partnerCompany = partnerCompanies.find(
      (company) => company.id === selectedPartnerId
    )
    if (!partnerCompany) return

    const companyName = partnerCompany.name
    const companyLogo = 'logo165x90white.png' // Replace with path to your company logo
    const totalCost = statistics.total

    var frame1 = document.createElement('iframe')
    frame1.name = 'frame1'
    frame1.style.position = 'absolute'
    frame1.style.top = '-1000000px'
    document.body.appendChild(frame1)
    var frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
      ? frame1.contentDocument.document
      : frame1.contentDocument
    frameDoc.document.open()

    frameDoc.document.write(`<html><head>
    <title>Invoice Print</title>
    <style>
      body { font-family: Arial, sans-serif; }
      .invoice-header {
          text-align: center; 
          margin-bottom: 50px;
          display: flex;
          align-items: center;
          justify-content: start;
          padding: 20px;
          border-bottom: 2px solid #000;
        }

        .invoice-details {
          flex-grow: 1;
        }

        .invoice-details h1,
        .invoice-details h2 {
          margin: 0;
          padding: 0;
          text-align: left;
        }

        .invoice-details h1 {
          font-size: 20px;
          color: #333;
          margin-bottom: 5px;
        }

        .invoice-details h2 {
          font-size: 16px;
          color: #666;
        }

        .company-logo {
          width: 100px;
          margin-left: 20px;
        }
      .invoice-table { width: 100%; border-collapse: collapse; }
      .invoice-table th, .invoice-table td { border: 1px solid #ddd; padding: 8px; }
      .invoice-table th { background-color: #f2f2f2; }
      .total-cost { text-align: right; margin-top: 20px; }
      /* Page-break styles for printing */
      @media print {
        body, html {
          height: 100%;
        }

        tr {
          page-break-inside: avoid; /* Avoids breaking the content of the row */
          page-break-after: auto; /* Automatically inserts a page break after the element if needed */
        }

        thead {
          display: table-header-group; /* Ensures the header is repeated on each page */
        }

        tfoot {
          display: table-footer-group; /* Ensures the footer is repeated on each page */
        }
      }
    </style>
    </head><body>
    <div class="invoice-header">
      <div class="invoice-details">
        
        <h1>Фактура ${
          filterData.startDate !== filterData.endDate ? 'од' : 'за'
        } ${filterData.startDate}${
      filterData.startDate === filterData.endDate
        ? ''
        : ` до ${filterData.endDate}`
    }</h1>
        <h2>BeeRide Доо Скопје</h2>
        <h2>До: ${companyName}</h2>
      </div>
      <img src="${companyLogo}" alt="Company Logo" class="company-logo">
    </div>

    <table class="invoice-table">
      <tr><th>Патник</th><th>Од</th><th>До</th><th>Датум</th><th>Цена (МКД)</th></tr>`)

    // Iterate over each trip and add it to the table
    allData.forEach((trip) => {
      frameDoc.document.write(`<tr>
      <td>${trip.customer_name}</td>
      <td>${trip.pickupAddress}</td>
      <td>${trip.dropAddress}</td>
      <td>${moment(trip.bookingDate).format('lll')}</td>
      <td>${trip.fullPrice}</td>
    </tr>`)
    })

    frameDoc.document.write(`</table>
    <div class="total-cost">
      <strong>Total Cost:</strong> ${totalCost.toFixed(2)} MKD
    </div>
  </body></html>`)
    frameDoc.close()
    setTimeout(function () {
      // window.frames["frame1"].focus();
      window.frames['frame1'].print()
      document.body.removeChild(frame1)
    }, 500)
  }

  const columns = [
    {
      title: t('vehicle_no'),
      field: 'vehicle_number',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_cost'),
      field: 'fullPrice',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('booking_date'),
      field: 'bookingDate',
      render: (rowData) =>
        rowData.bookingDate ? moment(rowData.bookingDate).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('customer_name'),
      field: 'customer_name',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('assign_driver'),
      field: 'driver_name',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('payment_mode'),
      field: 'payment_mode',
      render: (rowData) =>
        rowData.payment_mode === 'cash' ? (
          <img alt="Cash" src={CashIcon} />
        ) : rowData.payment_mode === 'wallet' ? (
          <img alt="Wallet" src={WalletIcon} />
        ) : rowData.payment_mode === 'card' ? (
          <img alt="Card" src={CardIcon} />
        ) : rowData.payment_mode === 'partner' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <img
              alt="Partner"
              src={PartnerIcon}
              style={{ width: '30px', height: '30px' }}
            />
            <h5 style={{ fontWeight: '500' }}>Partner</h5>
          </div>
        ) : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('pickup_address'),
      field: 'pickupAddress',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('drop_address'),
      field: 'dropAddress',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('car_type'),
      field: 'carType',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_start_time'),
      field: 'tripdate',
      render: (rowData) =>
        rowData.tripdate ? moment(rowData.tripdate).format('lll') : null,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_start_time'),
      field: 'trip_start_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('trip_end_time'),
      field: 'trip_end_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('roundTrip'),
      render: (rowData) => (
        <span>{rowData.roundTrip === true ? t('yes') : t('no')}</span>
      ),
      hidden: true,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('deliveryPerson'),
      field: 'deliveryPerson',
      hidden: true,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('deliveryPersonPhone'),
      field: 'deliveryPersonPhone',
      hidden: true,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('pickUpInstructions'),
      field: 'pickUpInstructions',
      hidden: true,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('deliveryInstructions'),
      field: 'deliveryInstructions',
      hidden: true,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('tripInstructions'),
      field: 'tripInstructions',
      hidden: true,
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('total_time'),
      field: 'total_trip_time',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    {
      title: t('distance'),
      field: 'distance',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
    { title: t('booking_id'), field: 'id' },
    {
      title: t('booking_ref'),
      field: 'reference',
      cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' },
    },
  ]

  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
  })
  const [allData, setAllData] = useState([])
  const [selectedPartnerId, setSelectedPartnerId] = useState(null)

  const handleSelection = (event, value) => {
    // value will be the selected user object
    if (value) {
      setSelectedPartnerId(value.id)
    } else {
      setSelectedPartnerId(null)
    }
  }

  const loadBookingsAndMap = async (date, partnerId) => {
    const response = await fetchAllBookingsForPartner(date, partnerId)
    return response
      ? Object.keys(response).map((key) => {
          return { ...response[key], id: key }
        })
      : []
  }

  function convertDate(inputDate) {
    if (!inputDate) return null
    const parts = inputDate.split('-')
    return `${parts[2]}-${parts[1]}-${parts[0]}`
  }

  function getDatesInRange(startDate, endDate) {
    const start = dayjs(convertDate(startDate))
    const end = dayjs(convertDate(endDate))

    let dates = []

    let current = start
    while (current.isBefore(end) || current.isSame(end)) {
      dates.push(current.format('DD-MM-YYYY'))
      current = current.add(1, 'day')
    }
    return dates
  }
  // this will be used to fetch the completed bookings
  const fetchPartnerBookings = async () => {
    const dates = getDatesInRange(filterData.startDate, filterData.endDate)
    let res = []
    for (let i = 0; i < dates.length; i++) {
      const date = dates[i]
      const resData = await loadBookingsAndMap(date, selectedPartnerId)
      if (!resData) continue
      res = [...res, ...resData]
    }
    setAllData(res)
  }
  const [statistics, setStatistics] = useState({})
  useEffect(() => {
    const stats = allData?.reduce(
      (statistics, newBook) => {
        statistics.count++
        statistics.total += +newBook.fullPrice

        return statistics
      },
      {
        total: 0,
        count: 0,
      }
    )
    setStatistics(stats)
  }, [allData])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          marginBottom: 5,
        }}
      >
        <DateTimePicker
          name="bookingStartDate"
          onChange={(date) => {
            setFilterData((prev) => ({
              ...prev,
              startDate: dayjs(date).format('DD-MM-YYYY'),
            }))
          }}
          showTime
          label="start"
        />
        <DateTimePicker
          name="bookingEndDate"
          onChange={(date) => {
            setFilterData((prev) => ({
              ...prev,
              endDate: dayjs(date).format('DD-MM-YYYY'),
            }))
          }}
          showTime
          label="end"
        />

        <div style={{ width: 400 }}>
          <Autocomplete
            disablePortal
            id="company"
            options={partnerCompanies ?? []}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Company" />}
            onChange={handleSelection}
          />
        </div>
        <Button
          onClick={() => {
            if (!selectedPartnerId) return
            fetchPartnerBookings()
          }}
          disabled={!selectedPartnerId}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 15px',
            marginLeft: '10px',
            cursor: 'pointer',
            width: '100px',
          }}
        >
          Submit
        </Button>
      </div>
      <AppBar position="static">COMPLETED</AppBar>

      <MaterialTable
        title={t('booking_title')}
        columns={columns}
        data={allData}
        options={{
          exportButton: false,
          sorting: true,
          pageSize: 10, // initial page size
          pageSizeOptions: [10, 20, 50, 100, 200],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: t('search'),
            exportTitle: t('export'),
          },
          header: {
            actions: t('actions'),
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} ' + t('of') + ' {count}',
            labelRowsSelect: t('rows'),
            firstTooltip: t('first_page_tooltip'),
            previousTooltip: t('previous_page_tooltip'),
            nextTooltip: t('next_page_tooltip'),
            lastTooltip: t('last_page_tooltip'),
          },
        }}
      />
      <Box
        sx={{
          padding: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Invoice Statistics {filterData.startDate} - {filterData.endDate}
        </Typography>
        <Typography variant="body1">
          Total Bookings: {statistics.count}
        </Typography>
        <Typography variant="body1">
          Total Money: {statistics.total} MKD
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={printInvoice}
          sx={{ alignSelf: 'start' }}
        >
          Print Invoice
        </Button>
      </Box>
    </div>
  )
}

export default PrintInvoice
