import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Paper, Typography } from '@material-ui/core'
import DashboardCard from '../components/DashboardCard'
import Map from '../components/Map'
import { useSelector } from 'react-redux'
import CircularLoading from '../components/CircularLoading'
import { useTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { FirebaseContext } from 'common/src'

const StatCard = ({ title, count }) => (
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h5" component="h2">
        {count}
      </Typography>
    </CardContent>
  </Card>
)

const DriverTable = ({ title, data }) => (
  <div>
    <Typography variant="h6" component="div" style={{ padding: 16 }}>
      {title}
    </Typography>
    <TableContainer component={Paper}>
      <Table aria-label={`${title} table`}>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Vehicle Number</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.firstName}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell>{row.vehicleNumber}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>
                {row.queue ? (
                  <HighlightOffIcon style={{ color: 'red' }} />
                ) : (
                  <CheckCircleOutlineIcon style={{ color: 'green' }} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
)

const Dashboard = () => {
  const [mylocation, setMylocation] = useState(null)
  const [locations, setLocations] = useState([])
  const [driversStats, setDriversStats] = useState({}) //[active, queue, activeList, queueList
  const [dailygross, setDailygross] = useState(0)
  const [monthlygross, setMonthlygross] = useState(0)
  const [totalgross, setTotalgross] = useState(0)

  const [settings, setSettings] = useState({})
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir()
  const usersdata = useSelector((state) => state.usersdata)
  const bookinglistdata = useSelector((state) => state.bookinglistdata)
  const settingsdata = useSelector((state) => state.settingsdata)
  const auth = useSelector((state) => state.auth)
  const cars = useSelector((state) => state.cartypes.cars)
  const { api } = useContext(FirebaseContext)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(api.fetchActiveDrivers())
    return () => {
      // Cleanup function if needed
      api.fetchActiveDrivers()
    }
  }, [dispatch, api])

  useEffect(() => {
    if (mylocation == null) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          setMylocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (error) => console.log(error)
      )
    }
  }, [mylocation])

  useEffect(() => {
    if (settingsdata.settings) {
      setSettings(settingsdata.settings)
    }
  }, [settingsdata.settings])

  useEffect(() => {
    if (usersdata.activeDrivers) {
      const drivers = usersdata.activeDrivers
      // .filter(
      //   (user) =>
      //     user.usertype === 'driver' &&
      //     ((user.fleetadmin === auth.info.uid &&
      //       auth.info.profile.usertype === 'fleetadmin') ||
      //       auth.info.profile.usertype === 'admin')
      // )
      let locs = []
      const driverStats = {
        active: 0,
        queue: 0,
        activeList: [],
      }
      for (let i = 0; i < drivers.length; i++) {
        let carImage
        for (let j = 0; j < cars.length; j++) {
          if (cars[j].name === drivers[i].carType) {
            carImage = cars[j].image
          }
        }
        locs.push({
          id: i,
          lat: drivers[i].location.lat,
          lng: drivers[i].location.lng,
          drivername: drivers[i].firstName + ' ' + drivers[i].lastName,
          carnumber: drivers[i].vehicleNumber,
          cartype: drivers[i].carType,
          carImage: carImage,
          queue:drivers[i].queue
        })

        driverStats.active++
        driverStats.activeList.push(drivers[i])

        if (drivers[i].queue) {
          driverStats.queue++
        }
      }
      setDriversStats(driverStats)
      setLocations(locs)
    }
  }, [usersdata.activeDrivers, auth.profile, auth.profile.uid, cars])

  useEffect(() => {
    if (bookinglistdata.bookings) {
      let today = new Date()
      let convenniencefees = 0
      let totconvenienceTrans = 0
      let todayConvenience = 0
      for (let i = 0; i < bookinglistdata.bookings.length; i++) {
        if (
          bookinglistdata.bookings[i].status === 'PAID' ||
          bookinglistdata.bookings[i].status === 'COMPLETE'
        ) {
          const { tripdate, convenience_fees } = bookinglistdata.bookings[i]
          let tDate = new Date(tripdate)
          if (convenience_fees > 0) {
            if (
              tDate.getMonth() === today.getMonth() &&
              tDate.getFullYear() === today.getFullYear()
            ) {
              convenniencefees = convenniencefees + parseFloat(convenience_fees)
            }
            if (
              tDate.getDate() === today.getDate() &&
              tDate.getMonth() === today.getMonth()
            ) {
              todayConvenience = todayConvenience + parseFloat(convenience_fees)
            }
            totconvenienceTrans =
              totconvenienceTrans + parseFloat(convenience_fees)
          }
        }
      }
      setDailygross(parseFloat(todayConvenience).toFixed(settings.decimal))
      setMonthlygross(parseFloat(convenniencefees).toFixed(settings.decimal))
      setTotalgross(parseFloat(totconvenienceTrans).toFixed(settings.decimal))
    }
  }, [bookinglistdata.bookings, settings])

  return bookinglistdata.loading || usersdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Typography
        variant="h4"
        style={{
          margin: '20px 20px 0 15px',
          textAlign: isRTL === 'rtl' ? 'right' : 'left',
        }}
      >
        {t('gross_earning')}
      </Typography>
      <Grid container direction="row" spacing={2}>
        <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
          {settings.swipe_symbol === false ? (
            <DashboardCard
              title={t('today_text')}
              image={require('../assets/img/money1.jpg').default}
            >
              {settings.symbol + ' ' + dailygross}
            </DashboardCard>
          ) : (
            <DashboardCard
              title={t('today_text')}
              image={require('../assets/img/money1.jpg').default}
            >
              {dailygross + ' ' + settings.symbol}
            </DashboardCard>
          )}
        </Grid>
        <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
          {settings.swipe_symbol === false ? (
            <DashboardCard
              title={t('this_month_text')}
              image={require('../assets/img/money2.jpg').default}
            >
              {settings.symbol + ' ' + monthlygross}
            </DashboardCard>
          ) : (
            <DashboardCard
              title={t('this_month_text')}
              image={require('../assets/img/money2.jpg').default}
            >
              {monthlygross + ' ' + settings.symbol}
            </DashboardCard>
          )}
        </Grid>
        <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
          {settings.swipe_symbol === false ? (
            <DashboardCard
              title={t('total')}
              image={require('../assets/img/money3.jpg').default}
            >
              {settings.symbol + ' ' + totalgross}
            </DashboardCard>
          ) : (
            <DashboardCard
              title={t('total')}
              image={require('../assets/img/money3.jpg').default}
            >
              {totalgross + ' ' + settings.symbol}
            </DashboardCard>
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs>
          {!!driversStats.active && (
            <StatCard title="Active Drivers" count={driversStats.active} />
          )}
        </Grid>
        <Grid item xs>
          {!!driversStats.queue && (
            <StatCard title="Drivers in Queue" count={driversStats.queue} />
          )}
        </Grid>
      </Grid>
      {mylocation ? (
        <Paper style={{ marginTop: '25px' }}>
          <Typography
            variant="h4"
            style={{
              margin: '20px 20px 0 15px',
              textAlign: isRTL === 'rtl' ? 'right' : 'left',
            }}
          >
            {t('real_time_driver_section_text')}
          </Typography>
          <Map
            mapcenter={mylocation}
            locations={locations}
            loadingElement={<div style={{ height: `480px` }} />}
            containerElement={<div style={{ height: `480px` }} />}
            mapElement={<div style={{ height: `480px` }} />}
          />
        </Paper>
      ) : (
        <Typography
          variant="h6"
          style={{ margin: '20px 0 0 15px', color: '#FF0000' }}
        >
          {t('allow_location')}
        </Typography>
      )}

      <div>
        {!!driversStats.activeList?.length && (
          <DriverTable
            title="Active Drivers List"
            data={driversStats.activeList}
          />
        )}
      </div>
    </div>
  )
}

export default Dashboard
