import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_OUT,
  CLEAR_LOGIN_ERROR,
  UPDATE_USER_PROFILE,
  USER_DELETED,
  REQUEST_OTP,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
} from '../store/types'
import {
  onAuthStateChanged,
  signInWithCredential,
  signInWithPopup,
  signOut as signOutAuth,
  signInWithCustomToken,
} from 'firebase/auth'

import store from '../store/store'

export const readProfile = () => async (firebase) => {
  const { auth, singleUserRef } = firebase
  const uid = auth.currentUser.uid
  const snapshot = await singleUserRef(uid).once('value')
  return snapshot.val()
}

export const fetchProfile = () => (dispatch) => (firebase) => {
  const { auth, singleUserRef } = firebase
  const uid = auth.currentUser.uid
  singleUserRef(uid).once('value', (snapshot) => {
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: snapshot.val(),
    })
  })
}

export const monitorProfileChanges = () => (dispatch) => (firebase) => {
  const { auth, singleUserRef } = firebase
  const uid = auth.currentUser.uid

  const unsubscribeFunctions = []

  const monitorProperty = (property) => {
    const onValueChange = (res) => {
      const currentProfile = store.getState().auth.profile || {}
      if (
        property === 'walletBalance' &&
        currentProfile?.usertype !== 'driver'
      ) {
        return
      }

      singleUserRef(uid).once('value', (snapshot) => {
        const newProfile = snapshot.exists() ? snapshot.val() : {}
        if (currentProfile[property] !== newProfile[property]) {
          dispatch({
            type: UPDATE_USER_PROFILE,
            payload: newProfile,
          })
        }
      })
    }

    const propertyRef = singleUserRef(uid).child(property)
    propertyRef.on('value', onValueChange)
    unsubscribeFunctions.push(() => propertyRef.off('value', onValueChange))
  }

  monitorProperty('queue')
  monitorProperty('walletBalance')
  monitorProperty('customDriverRadius')
  monitorProperty('driverActiveStatus')
  monitorProperty('password')
  monitorProperty('mobile')
  monitorProperty('email')
  monitorProperty('phoneNumberVerified')
  monitorProperty('defaultPaymentMethod')

  return unsubscribeFunctions
}

export const fetchUser = () => (dispatch) => (firebase) => {
  const { auth, singleUserRef } = firebase

  dispatch({
    type: FETCH_USER,
    payload: null,
  })

  onAuthStateChanged(auth, (user) => {
    if (user) {
      singleUserRef(user.uid).once('value', async (snapshot) => {
        if (snapshot.val()) {
          let profile = snapshot.val()
          profile.uid = user.uid

          if (profile.approved) {
            dispatch({
              type: FETCH_USER_SUCCESS,
              payload: profile,
            })
          } else {
            signOutAuth(auth)
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: {
                code: store.getState().languagedata.defaultLanguage.auth_error,
                message:
                  store.getState().languagedata.defaultLanguage
                    .require_approval,
              },
            })
          }
        } else {
          let regType
          let mobile = ' '
          let email = ' '
          let firstName = ''
          let lastName = ' '
          let profile_image = null

          if (user.providerData.length === 0 && user.email) {
            regType = 'email'
            email = user.email
          }

          if (user.providerData.length > 0 && user.phoneNumber) {
            mobile = user.phoneNumber
            regType = 'mobile'
          }

          if (user.providerData.length > 0) {
            const provideData = user.providerData[0]
            if (provideData === 'phone') {
              mobile = provideData.phoneNumber
              regType = 'mobile'
            }
            if (
              provideData.providerId === 'facebook.com' ||
              provideData.providerId === 'apple.com'
            ) {
              regType = 'social'
              if (provideData.email) {
                email = provideData.email
              }
              if (provideData.phoneNumber) {
                mobile = provideData.phoneNumber
              }
              if (provideData.displayName) {
                if (provideData.displayName.split(' ').length > 0) {
                  firstName = provideData.displayName.split(' ')[0]
                  lastName = provideData.displayName.split(' ')[1]
                } else {
                  firstName = provideData.displayName
                }
              }
              if (provideData.photoURL) {
                profile_image = provideData.photoURL
              }
            }
          }
          const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
          const reference = [...Array(5)]
            .map((_) => c[~~(Math.random() * c.length)])
            .join('')
          let userData = {
            uid: user.uid,
            createdAt: new Date().toISOString(),
            firstName: firstName,
            lastName: lastName,
            mobile: mobile,
            email: email,
            usertype: 'rider',
            referralId: reference,
            phoneNumberVerified: true,
            defaultPaymentMethod: 'cash',
            approved: true,
            walletBalance: 0,
            regType: regType,
          }

          if (profile_image) {
            userData['profile_image'] = profile_image
          }

          singleUserRef(user.uid).set(userData)
          user.profile = userData
          dispatch({
            type: FETCH_USER_SUCCESS,
            payload: userData,
          })
        }
      })
    } else {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: {
          code: store.getState().languagedata.defaultLanguage.auth_error,
          message: store.getState().languagedata.defaultLanguage.not_logged_in,
        },
      })
    }
  })
}

export const validateReferer = (referralId) => async (firebase) => {
  const { config } = firebase
  const response = await fetch(
    `https://${config.projectId}.web.app/validate_referrer`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        referralId: referralId,
      }),
    }
  )
  const json = await response.json()
  return json
}

export const checkUserExists = (regData) => async (firebase) => {
  const { config } = firebase
  const response = await fetch(
    `https://${config.projectId}.web.app/check_user_exists`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: regData.email,
        mobile: regData.mobile,
      }),
    }
  )
  const json = await response.json()
  return json
}

export const mainSignUp = (regData) => async (firebase) => {
  const { config, driverDocsRef } = firebase
  let url = `https://${config.projectId}.web.app/user_signup`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ regData: regData }),
  })
  const res = await response.json()
  if (res.uid) {
    await driverDocsRef(res.uid).put(regData.licenseImage)
  }
  return res
}

export const requestEmailOtp = (email) => (dispatch) => async (firebase) => {
  const { config } = firebase
  dispatch({
    type: REQUEST_OTP,
    payload: true,
  })
  let url = `https://${config.projectId}.web.app/request_email_otp`
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
    const result = await response.json()
    if (result.success) {
      dispatch({
        type: REQUEST_OTP_SUCCESS,
        payload: true,
      })
    } else {
      dispatch({
        type: REQUEST_OTP_FAILED,
        payload: result.error,
      })
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: REQUEST_OTP_FAILED,
      payload: error,
    })
  }
}

export const verifyEmailOtp =
  (email, otp) => (dispatch) => async (firebase) => {
    const { auth, config } = firebase
    const body = {
      email: email,
      otp: otp,
    }
    try {
      let url = `https://${config.projectId}.web.app/verify_email_otp`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      const result = await response.json()
      if (result.token) {
        signInWithCustomToken(auth, result.token)
          .then((user) => {
            //OnAuthStateChange takes care of Navigation
          })
          .catch((error) => {
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: error,
            })
          })
      } else {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: result.error,
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error,
      })
    }
  }

export const checkUserExistsAndHasPassword =
  (phoneNumber) => async (firebase) => {
    const { config } = firebase

    try {
      const response = await fetch(
        `https://us-central1-${config.projectId}.cloudfunctions.net/checkUserPasswordStatus`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber }),
        }
      )

      const result = await response.json()

      return result
    } catch (error) {
      console.error(error)
      throw new Error('Error checking user existence')
    }
  }

export const removeUserPasswordAndPhoneVerification =
  (phoneNumber) => async (firebase) => {
    const { config } = firebase

    try {
      const response = await fetch(
        `https://us-central1-${config.projectId}.cloudfunctions.net/removeUserPasswordAndPhoneVerification`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber }),
        }
      )

      const result = await response.json()

      return result
    } catch (error) {
      console.error(error)
      throw new Error('Error checking user existence')
    }
  }

export const verifyPhoneAndPassword =
  (phoneNumber, password) => (dispatch) => async (firebase) => {
    const { auth, config } = firebase

    try {
      const response = await fetch(
        `https://us-central1-${config.projectId}.cloudfunctions.net/verifyPhoneAndPassword`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber, password }),
        }
      )

      const result = await response.json()

      if (result.token) {
        signInWithCustomToken(auth, result.token)
          .then((user) => {
            //OnAuthStateChange takes care of Navigation
          })
          .catch((error) => {
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: error,
            })
          })
      } else {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: result.error,
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error,
      })
    }
  }

export const requestPhoneOtpDevice = (verificationId) => async (dispatch) => {
  dispatch({
    type: REQUEST_OTP_SUCCESS,
    payload: verificationId,
  })
}

// export const requestPhoneOtpDevice =
//   (phoneNumber, appVerifier) => (dispatch) => async (firebase) => {
//     const { phoneProvider } = firebase
//     dispatch({
//       type: REQUEST_OTP,
//       payload: null,
//     })
//     try {
//       const verificationId = await phoneProvider.verifyPhoneNumber(
//         phoneNumber,
//         appVerifier
//       )
//       dispatch({
//         type: REQUEST_OTP_SUCCESS,
//         payload: verificationId,
//       })
//     } catch (error) {
//       dispatch({
//         type: REQUEST_OTP_FAILED,
//         payload: error,
//       })
//     }
//   }

export const mobileSignIn =
  (verficationId, code) => (dispatch) => (firebase) => {
    const { auth, mobileAuthCredential, singleUserRef } = firebase

    dispatch({
      type: USER_SIGN_IN,
      payload: null,
    })

    signInWithCredential(auth, mobileAuthCredential(verficationId, code))
      .then(async (userCredential) => {
        //OnAuthStateChange takes care of Navigation
        const user = userCredential.user

        try {
          const snapshot = await singleUserRef(user.uid).once('value')
          if (snapshot.val()) {
            await singleUserRef(user.uid).update({
              phoneNumberVerified: true,
            })
          }
        } catch (error) {
          console.error('Error fetching user data:', error)
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        })
      })
  }

export const facebookSignIn = (token) => (dispatch) => (firebase) => {
  const { auth, facebookProvider, facebookCredential } = firebase

  dispatch({
    type: USER_SIGN_IN,
    payload: null,
  })
  if (token) {
    const credential = facebookCredential(token)
    signInWithCredential(auth, credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch((error) => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        })
      })
  } else {
    signInWithPopup(auth, facebookProvider)
      .then(function (result) {
        var token = result.credential.accessToken
        const credential = facebookCredential(token)
        signInWithCredential(auth, credential)
          .then((user) => {
            //OnAuthStateChange takes care of Navigation
          })
          .catch((error) => {
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: error,
            })
          })
      })
      .catch(function (error) {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        })
      })
  }
}

export const appleSignIn = (credentialData) => (dispatch) => (firebase) => {
  const { auth, appleProvider } = firebase

  dispatch({
    type: USER_SIGN_IN,
    payload: null,
  })
  if (credentialData) {
    const credential = appleProvider.credential(credentialData)
    signInWithCredential(auth, credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch((error) => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        })
      })
  } else {
    signInWithPopup(auth, appleProvider)
      .then(function (result) {
        signInWithCredential(auth, result.credential)
          .then((user) => {
            //OnAuthStateChange takes care of Navigation
          })
          .catch((error) => {
            dispatch({
              type: USER_SIGN_IN_FAILED,
              payload: error,
            })
          })
      })
      .catch(function (error) {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error,
        })
      })
  }
}

export const signOut = () => (dispatch) => (firebase) => {
  const { auth, singleUserRef } = firebase

  const uid = auth?.currentUser?.uid

  if (!uid) {
    // No user is signed in, just dispatch sign out action
    dispatch({
      type: USER_SIGN_OUT,
      payload: null,
    })
    return
  }

  singleUserRef(uid).off()

  singleUserRef(uid).once('value', (snapshot) => {
    if (snapshot.val()) {
      const profile = snapshot.val()
      if (profile && profile.usertype === 'driver') {
        singleUserRef(uid).update({ driverActiveStatus: false })
      }

      auth
        .signOut()
        .then(() => {
          dispatch({
            type: USER_SIGN_OUT,
            payload: null,
          })
        })
        .catch((error) => void 0)
    }
  })
}

export const deleteUser = (uid) => (dispatch) => (firebase) => {
  const { singleUserRef, auth } = firebase

  singleUserRef(uid).child('queue').off()
  singleUserRef(uid).child('mobile').off()
  singleUserRef(uid).child('walletBalance').off()
  singleUserRef(uid).child('ratings').off()

  singleUserRef(uid)
    .remove()
    .then(() => {
      if (auth.currentUser.uid === uid) {
        auth.signOut()
        dispatch({
          type: USER_DELETED,
          payload: null,
        })
      }
    })
}

export const updateProfile =
  (userAuthData, updateData) => (dispatch) => async (firebase) => {
    const {
      singleUserRef,
      carImageRef,
      frontIDImageRef,
      backIDImageRef,
      taxiLicenseImageRef,
      drivingLicenseImageRef,
      driverImageRef,
      vehicleInsuranceImageRef,
    } = firebase

    let profile = userAuthData

    if (updateData.carImage) {
      await carImageRef(userAuthData.uid).put(updateData.carImage)
      updateData.carImage = await carImageRef(userAuthData.uid).getDownloadURL()
    }

    if (updateData.frontIDImage) {
      await frontIDImageRef(userAuthData.uid).put(updateData.frontIDImage)
      updateData.frontIDImage = await frontIDImageRef(
        userAuthData.uid
      ).getDownloadURL()
    }
    if (updateData.backIDImage) {
      await backIDImageRef(userAuthData.uid).put(updateData.backIDImage)
      updateData.backIDImage = await backIDImageRef(
        userAuthData.uid
      ).getDownloadURL()
    }
    if (updateData.taxiLicenseImage) {
      await taxiLicenseImageRef(userAuthData.uid).put(
        updateData.taxiLicenseImage
      )
      updateData.taxiLicenseImage = await taxiLicenseImageRef(
        userAuthData.uid
      ).getDownloadURL()
    }
    if (updateData.drivingLicenseImage) {
      await drivingLicenseImageRef(userAuthData.uid).put(
        updateData.drivingLicenseImage
      )
      updateData.drivingLicenseImage = await drivingLicenseImageRef(
        userAuthData.uid
      ).getDownloadURL()
    }
    if (updateData.driverImage) {
      await driverImageRef(userAuthData.uid).put(updateData.driverImage)
      updateData.driverImage = await driverImageRef(
        userAuthData.uid
      ).getDownloadURL()
    }
    if (updateData.vehicleInsuranceImage) {
      await vehicleInsuranceImageRef(userAuthData.uid).put(
        updateData.vehicleInsuranceImage
      )
      updateData.vehicleInsuranceImage = await vehicleInsuranceImageRef(
        userAuthData.uid
      ).getDownloadURL()
    }

    profile = { ...profile, ...updateData }

    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: profile,
    })
    singleUserRef(userAuthData.uid).update(updateData)
  }

export const updateImage =
  (userAuthData, imageBlob, type) => (dispatch) => (firebase) => {
    const { singleUserRef, profileImageRef, frontIDImageRef } = firebase
    const toGetRefFunc =
      type === 'profile_image' ? profileImageRef : frontIDImageRef
    toGetRefFunc(userAuthData.uid)
      .put(imageBlob)
      .then(() => {
        imageBlob.close()
        return toGetRefFunc(userAuthData.uid).getDownloadURL()
      })
      .then((url) => {
        let profile = userAuthData
        profile[type] = url
        singleUserRef(userAuthData.uid).update({
          [type]: url,
        })
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: profile,
        })
      })
  }

export const updateWebProfileImage =
  (userAuthData, imageBlob) => (dispatch) => async (firebase) => {
    const { singleUserRef, profileImageRef } = firebase

    await profileImageRef(userAuthData.uid).put(imageBlob)
    let image = await profileImageRef(userAuthData.uid).getDownloadURL()
    let profile = userAuthData
    profile.profile_image = image
    singleUserRef(userAuthData.uid).update(profile)
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: profile,
    })
  }

export const updatePushToken = (uid, token, platform) => (firebase) => {
  const { singleUserRef } = firebase

  singleUserRef(uid).update({
    pushToken: token,
    userPlatform: platform,
  })
}

export const clearLoginError = () => (dispatch) => (firebase) => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
    payload: null,
  })
}
